import React, { useEffect, useState, useContext } from 'react';
import axios from'axios'
//services
import { useMutation, useQuery } from 'react-query';
import { useToken } from './session.service'
import { URL, USER_ROUTES } from './tgd.config'
import { useUser } from './person.service';


//AXIOS REPO
//-------------------------

export const  getAllResources = (endpoint,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const  updateNotification = (data) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + data.token;
    let config = {
        method: "post",
        url:`${URL}/UpdateNotificationState`,
        data:data.parameters
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const createNotification = ({notification,token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/CreateNotification`,
        data:notification
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}


//react-query HOOkS
//-------------------------

export const useUnReadedNotifications = () => {
    const token = useToken()
    return useQuery(['Notifications',token],getAllResources,{refetchInterval:5000})
}

export const useReadedNotifications = () => {
    const token = useToken()
    return useQuery(['NotificationsReaded',token],getAllResources)
}
 export const useAddNotification = () => {
     return useMutation(createNotification)
 }


//CUSTOM HJOOKS
//-------------------------

export const useReadUnreadNotification = ()=>{

    const {data:user} = useUser()
    const token = useToken()
    const {data:notifications,refetch:refetchNotifications} = useUnReadedNotifications()
    const {data:unreadedNotif,refetch:refetchUnreadedNotif} = useReadedNotifications()

    const refetch = () => {
        refetchNotifications()
        refetchUnreadedNotif()
    }
    const [mutate] = useMutation(updateNotification,{
        onSuccess: () => refetch()
    })

    const readOne = (item) => {
        let parameters = {
            email:user.email,
            notificationId:item.id,
            state:'Leido'
        }
        mutate({parameters,token})
    }
    
    const unReadOne = (item) =>{
        let parameters = {
            email:user.email,
            notificationId:item.id,
            state:'Sin leer'
        }
        mutate({parameters,token})
    } 

    const readAll = () =>{
        notifications.forEach(item => {
            readOne(item)
        });
    }

    return [readOne, unReadOne, readAll]
    
}