import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
      width: 'auto',
      padding:'24px',
      boxSizing:'border-box'
    },

}));

const PageContainer = ({children, color}) => {
    const classes = useStyles();

    return (
        <div style={{backgroundColor:color}} className={classes.pageContainer}>
            {children}
        </div>
    );
}

export default PageContainer;
