import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react';

//Styles of material ui components
const useStyles = makeStyles((theme) => ({

    dimentionSelector:{
        display:'flex',
        color:'#fff',
        textTransform:'uppercase',
        minHeight:'104px',
        maxHeight:'128px',
        minWidth:'104px',
        maxWidth:'128px',
        margin:'8px',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'5px',
        cursor:'pointer',
        position:'relative',


    },

}));

const GridItem = ({disabled,color,item,action}) => {

    const classes = useStyles()
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [item]);

    const loadingHandler = (item) => {
        if(disabled){
            setLoading(false)
        }
        if(!disabled){
            setLoading(!loading)
            action()
        }
    }

    return (
        <Box 
            onClick={loadingHandler}
            id={item.name}
            className={classes.dimentionSelector} 
            style={{
                backgroundColor:disabled ? '#707070' : color, 
                cursor: disabled ? 'not-allowed': 'pointer'
            }}> 
                {/* content */}
                { loading ? <CircularProgress color={'light'}/>
                :<>
                    {item.factory || item.factoryId
                    ?
                        <>
                            {item.factory ?<>{item.factory}<br/></>:null} 
                            {item.name}
                        </>
                    :
                        <>
                            {item.tagFP?<>{item.tagFP.split('|')[0]}<br/></>:null}{/* factory name */}
                            {item.tagFP?<>{item.tagFP.split('|')[1]}</>:null}{/* Palnt Name */}
                        </>
                    }
                </>
                }
        </Box>
    );
}

export default GridItem;
