import React, { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'

const useStyles = makeStyles({
    'icon-1': { color: 'green' },
    'icon-2': { color: 'orange' },
    'icon-3': { color: 'red' },
    'icon-4': { color: 'black' }
    });
    


const ColorRating = ({type,title,max,handleState}) => {

    const classes = useStyles()

    const [value, setValue] = useState(1)
    const [hover, setHover] = useState(-1)
    const indicator = {
        1:{label:'Baja'},
        2:{label:'Media'},
        3:{label:'Alta'},
        4:{label:'Urgente'},
    }

    useEffect(()=> {
        handleState(type,indicator[value] ? indicator[value].label:'Sin Seleccionar')
    },[value])

   return (

        <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">{title}</Typography>
        <Rating
        classes={{
            iconFilled: classes[`icon-${Math.ceil(value)}`],
            iconHover: classes[`icon-${Math.ceil(hover)}`],
        }}
        name={title}
        value={value}
        precision={1}
        max={max}
        onChange={(event, newValue) => setValue(newValue)}
        onChangeActive={(event, newHover) => setHover(newHover)}
        icon={<FiberManualRecordIcon fontSize="inherit" />}
        />
        <br/>
        {indicator[value] ? indicator[value].label:'Sin Seleccionar'}
        </Box>
    )
}

export default ColorRating;
