import React from 'react';
//components
import AdminContent from '../pages/rolePages/AdminContent';
import SuperAdminContent from '../pages/rolePages/SuperAdminContent';
import AccountManagerContent from '../pages/rolePages/AccountManagerContent';
import EngineerContent from '../pages/rolePages/EngineerContent';
import OperatorContent from '../pages/rolePages/OperatorContent';
import DashLayout from './DashLayout'
import Loading from '../components/presentational/Loading';
import ClientContent from '../pages/rolePages/ClientContent';

//Router
import {Redirect,Route,  Switch} from 'react-router-dom'

//services
import { useUser, useDimention } from '../services/person.service';
import { USER_ROUTES} from '../services/tgd.config';
import { usePbiAccessToken } from '../services/pbi.service';
import TgdModal from '../components/TgdModal';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import LogOutButton from '../components/login/LogOutButton';


const UserRol = () => {
  
    const {data:user, error:userError, status:userStatus} = useUser()
    const {data:aadToken,error:pbiTokenError, status:pbiTokenstatus} = usePbiAccessToken()
    const dimention = useDimention()
    
    
    if((userStatus === 'loading') || (pbiTokenstatus === 'loading') ) return <><Loading/></>
    if(!user || !aadToken )return <TgdModal open={true} fullWidth={true}defaultButton={<LogOutButton width={'240px'}/>}><Alert severity={'warning'}>Estamos mejorando Tgd Soft! Disculpe las molestias. Vuelva a intentarlo en unos minutos</Alert></TgdModal>
    if(user && (user.type !== 'C') && aadToken ) {
        return(
                <>
                <Redirect to={`/${USER_ROUTES.home}/${dimention}`}/>
                <Switch>
                    <DashLayout >
                        <Switch>
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.superAdmin}`}><SuperAdminContent dimention={dimention} /></Route>{/* SA */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.administrator}`}><AdminContent dimention={dimention} /></Route>{/* A */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.accountManager}`}><AccountManagerContent dimention={dimention} /></Route>{/* AC */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.engineer}`}><EngineerContent dimention={dimention}/></Route>{/* I */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.operator}`}><OperatorContent dimention={dimention}/></Route>{/* O */}
                        </Switch>
                    </DashLayout>
                </Switch>
            </>
        )
    }
    if(user && (user.type === 'C') && aadToken){
        return (
            <>
                <Redirect to={USER_ROUTES.home}/><ClientContent aadToken={aadToken}/>
            </>
        )
    }
    return <><Loading /> </>
           
    
}

export default UserRol;