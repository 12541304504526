import { useMutation, useQuery } from "react-query"
import axios from'axios'
import { URL } from "./tgd.config"
//---------------------
//AXIOS REPOSITORY
//---------------------
export const  newDemoRequest = ({parameters}) => {
    let res = axios.post(`${URL}/DemoRequest`, parameters, null).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}



//---------------------
//USE QUERYS
//---------------------




//---------------------
//MUTATIONS
//---------------------

export const useNewDemoRequest = () => {
    return useMutation(newDemoRequest)
}



//---------------------
//CUSTOM HOOOKS
//---------------------