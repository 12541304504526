import React from 'react';

import HomeHeroImg from '../assets/img/welcomeHeader.png'
import { Button,  Icon, makeStyles, Paper, Typography } from '@material-ui/core';
import {  useUser } from '../services/person.service';
import { Link } from 'react-router-dom';
import { USER_ROUTES } from '../services/tgd.config';

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        gap: '24px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        /* height:'500px', */
        /* margin: theme.spacing(3), */
        marginTop:'24px',
        width: 'auto',
        '& > *': {
            margin: theme.spacing(0),
            flex: '1',
            minWidth: theme.spacing(30),
            /* maxHeight: theme.spacing(30),
            minHeight: theme.spacing(18), */
            padding: theme.spacing(2),
        },
    },

    heroNavPlant: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(-10),
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(-10),
            marginBottom: theme.spacing(0)
        },
        '& > *': {
            width: 'calc(100% - 48px)',
            marginTop: theme.spacing(-10),
            marginLeft: theme.spacing(6),
            marginBottom: theme.spacing(4),
            color: '#f3f3f3',
            textTransform: 'uppercase',
            fontWeight: '900',
            letterSpacing: '1px',
        },
    },
    hero: {
        height: '20vh',
        width: '100%',
        backgroundPosition: 'center',
    },

}));

const HomeContent = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            {children}
        </div>
    )
}

const HomeHero = ({ title }) => {
    const classes = useStyles();
    
    return (
        <>
            <Paper className={classes.hero} style={{ backgroundColor: '#f3f3f3', backgroundImage: `url(${HomeHeroImg})`, }} elevation={4} />
            <div className={classes.heroNavPlant}>
                <Typography variant="h5" component="h5">
                    {title}
                </Typography>
            </div>
            <br />
        </>
    )
}

const ButtonHomeNavigation = ({dimention}) => {
    const {data:user,status} = useUser()

    return(
        <>
        
                {(user.type === 'A') || (user.type === 'AC') || (user.type === 'SA') 
                ?<>
                    {user.type === 'A' || (user.type === 'SA') ?
                        <Button component={Link} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`} variant="contained" color="primary" >
                            <Icon style={{ margin: '0 16px' }}>new_releases</Icon>
                            Prospectos
                        </Button>
                    :null}
                    <Button component={Link} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.factories}`} variant="contained" color="primary" >
                        <Icon style={{ margin: '0 16px' }}>business</Icon>
                        Gestionar Fábricas
                    </Button>
                    <Button component={Link} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.plants}`} variant="contained" color="primary" >
                        <Icon style={{ margin: '0 16px' }}>business</Icon>
                        Gestionar plantas
                    </Button>
                </>
                :null
                }
                <Button component={Link} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`} variant="contained" color="primary" >
                    <Icon style={{ margin: '0 16px' }}>personAdd</Icon>
                    Configurar Usuarios
                </Button>
                <div></div>
                <div></div>
        </>
    )
}

const HomePage = ({dimention}) => {

    return (
        <div>
            <HomeHero title={dimention} />
            <HomeContent>
                <ButtonHomeNavigation dimention={dimention}/>
            </HomeContent>
        </div>
    );
}

export default HomePage;
