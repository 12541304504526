import React, {useState,useEffect,useContext} from 'react';
//services
import {getPbiGroups,getPbiReportsInGroup,getPbiAccesToken} from '../services/axiosPbi'
import {newMenuItemWithSubMenu} from '../services/tgd.config'
import {getIndicators,setNewMenu} from '../services/tgd.config'

import {SessionContext} from './sessionContext'
import { UserContext } from './userContext';




export const PbiContext = React.createContext()

//Making provider component to pass states 
export const PbiContextProvider = (props) => {
    
    const [auth,setAuth] = useContext(UserContext);
    const [pbi,setPbi] = useState({})
    const [user,setUser] = useContext(UserContext);

    //Get Acces Token to connect powerBi API
    useEffect(() => {
            getPbiAccesToken('http://localhost:55226/aad/token')
            .then(res=>{
                /* console.log('accessToken', res.data) */
                setPbi({...pbi,accessToken:res.data})
            })
            .catch(err=>console.log(err))   
    },[]);  

    //Get Groups
    useEffect(() => {
        if(pbi.accessToken){
            getPbiGroups(pbi.accessToken)
            .then(groups => {
                /* console.log('groups',groups.data.value) */
                setPbi({...pbi,groups:groups.data.value})
            })
            .catch(err => console.log(err))    
        }
    },[pbi.accessToken]);



    //search group by plant
    useEffect(()=>{
        if((user.plants) && (user.user) && (user.user.type === 'C')){
            if(user.plants.length < 2){
            if((pbi.groups) && (auth.user)){
                const factory = user.plants[0].factory
                const plant = user.plants[0].name
                const workspaceName = factory+' '+ plant
                const g = pbi.groups.filter(group => group.name === workspaceName)
                setPbi({...pbi,userWorkspace:g[0]})
            }
            }
        }
        },[pbi.groups])
        

    //Get Reports of a group by user name
    useEffect(()=>{
        if(pbi.userWorkspace){
            getPbiReportsInGroup('https://api.powerbi.com/v1.0/myorg/groups/'+ pbi.userWorkspace.id +'/reports',pbi.accessToken)
            .then(res => {
                setPbi({...pbi,reports:res.data.value})
            })
            .catch(err => console.log(err))
        }
    },[pbi.userWorkspace])


    //Get Dashboards of a group by user name
    useEffect(()=>{
        if(pbi.reports){
            getPbiReportsInGroup('https://api.powerbi.com/v1.0/myorg/groups/'+ pbi.userWorkspace.id +'/dashboards',pbi.accessToken)
            .then(res => {
                /* console.log(res.data.value) */
                setPbi({...pbi,dashboards:res.data.value})
            })
            .catch(err => console.log(err))
        }
    },[pbi.reports])


    //Get tiles of report 
    useEffect(()=>{
        if(pbi.dashboards){
            pbi.dashboards.forEach( dash => {
                getPbiReportsInGroup(`https://api.powerbi.com/v1.0/myorg/dashboards/${dash.id}/tiles`)
                .then(res => {
                    /* console.log(res.data.value) */
                    setPbi({...pbi,tiles:res.data.value})
                })
                .catch(err => console.log(err)) 
            });
        }
    },[pbi.dashboards])

    //Set Menu items to default menu
    useEffect(() => {
        if((pbi.reports) && (pbi.reports.length > 0)){
            setUser({...user,menu:setNewMenu(pbi.reports,user.menu)})
        }
        /* onsole.log(pbi.reports) */
    }, [pbi.reports]);

    return(
        <PbiContext.Provider value={[pbi,setPbi]}>
            {props.children}
        </PbiContext.Provider>
    )
}