import { useEffect, useContext } from 'react';
import axios from'axios'
//services
import { getAllResources } from './tgd.axios.service'
import { useMutation, useQuery } from 'react-query';
import {useToken} from './session.service'
import { URL } from './tgd.config';


import { SessionContext } from '../store/sessionContext';


//axios repo
//---------------------------------------------
export const updatePerson = ({body,token}) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/UpdatePassword`,
        data:body
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const updateHiddenState = ({parameters,token}) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/UpdateHiddenState`,
        data:parameters
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const getSubordinates = (endpoint,refererId,token) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/${endpoint}/${refererId}`
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const createPerson = ({endpoint, parameters, token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/${endpoint}`,
        data:parameters
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const createDemoClient = ({parameters, token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/CreateDemoClient`,
        data:parameters
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const desactivateDemoUser = ({parameters, token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/UpdateDemoUser`,
        data:parameters
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
//---------------------------------------------
//react-query HOOKS
//---------------------------------------------

export const useAllPersons = () => {
    const token = useToken()
    return useQuery(['AllPerson',token], getAllResources)
}

export const useUser = () => {
    const token = useToken()
    return useQuery(['User',token], getAllResources,{
        retry:0
    })
}

export const usePlants = () => {
    const token = useToken()
    return useQuery(['Plants',token], getAllResources)
}

export const usePerson = () => {
    const token = useToken()
    return useQuery(['Person',token], getAllResources)
}

export const usePersonName = () => {
    const {data:person} = usePerson()
    if(person) return person[0].name
    return null
}

export const useSubordinates = () => {
    const token = useToken()
    const {data:person} = usePerson()
    return useQuery(['Subordinates',person[0].id,token], getSubordinates,{
        enabled:!!person[0].id
    })
}

export const useDemoRequests = ( ) => {
    const token = useToken()
    return useQuery(['AllDemoRequests',token],getAllResources,{
    })
}

//react-query MUTATIONS
//---------------------------------------------

export const useCreateperson = () => {
    return useMutation(createPerson)
}

export const useUpdatePerson = () => {
    return useMutation(updatePerson,{})
}

export const useActivateDemoRequest = (config) => {
    return useMutation(createDemoClient,config)
}

export const useDesactivateDemoUser = (config) => {
    return useMutation(desactivateDemoUser,config)
}

export const useUpdateHiddenState = (config) => {
    return useMutation(updateHiddenState,config)
}

export const useUpdateDemoRequest = (config) => {
    const token = useToken()
    const [mutation,query] = useUpdateHiddenState(config)

    const archive = (email) => {
        mutation({
            parameters:{
                applicantsEmail:email,
                hidden:true
            },
            token
        })
    }

    const unArchive = (email) => {
        mutation({
            parameters:{
                applicantsEmail:email,
                hidden:false
            },
            token
        })
    }
    
    return {archive,unArchive,query}
}




//CUSTOM HOOKS
//---------------------------------------------

//hidden(archived)-Showed(pending) user Demos Requests
export const useShowedDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const pendingDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter(item => item.hidden === false):[]
    return [pendingDemoRequests,demoRequestsQuery]
}

export const useHiddenDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const hiddenDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter(item => item.hidden === true):[]
    return [hiddenDemoRequests,demoRequestsQuery]
}


//custom - Filtered users by Role
export const useClientsByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter(item => item.role === 'Cliente'):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter(item => item.role === 'Cliente'):[]
    if((user.type==='A')||(user.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useOperatorsByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter(item => item.role === 'Operario'):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter(item => item.role === 'Operario'):[]
    if((user.type==='A')||(user.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useEngineersByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter(item => item.role === 'Ingeniero'):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter(item => item.role === 'Ingeniero'):[]
    if((user.type==='A')||(user.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useAccountManagersByReferer = () => {
    const query = useSubordinates()
    const queryPersons = useAllPersons()
    const{data:user} = useUser()
    if(!query.data) return query
    if((user.type === 'A')||(user.type === 'SA'))return queryPersons.data.filter(item => item.role === 'Administrador de Cuentas')
    else return query.data.filter(item => item.role === 'Administrador de Cuentas' )
}
export const useAdministators = () => {
    const query = useAllPersons()
    const data = query.data ? query.data.filter(item => item.role === 'Administrador'):[]
    return [data,query]
}

export const useEmailVerificationExist = (email) => { 
    const {data, error} = useAllPersons()
    useEffect(() => {
        if(email && data){
            let query = data.filter(item => item.email === email )
            sessionStorage.setItem('emailVerification',query.length)
        }
    },[email])
}


export const useDimention = () => {
    const {data:person} = usePerson()
    const {data:user} =  useUser()
    let dimention = ''
    if(user && person){
        switch (user.type) {
            case 'SA':
                dimention = person[0].role.replace(/ /g,'-')
                return dimention
            case 'AC':
                dimention = person[0].role.replace(/ /g,'-')
                return dimention
            case 'A':
                dimention = person[0].role
                return dimention
            case 'I':
                dimention = 'Supervisor'
                return dimention
            case 'C':
                dimention = person[0].role
                return dimention
            case 'O':
                dimention = person[0].role
                return dimention
        }
    }
    return dimention
}




export const useWorkspace = () => {
    const [auth] = useContext(SessionContext)
    const{data:user} = useUser()
    const {data:plants} = usePlants()

    if(plants && user){
        if((user.type === 'C')){
            if((plants.length === 1)){
                let factory = plants[0].factory
                let plant = plants[0].name
                return factory + ' ' + plant
            }
            if(plants.length > 1){
                return auth.workspace
            }
        }
        if(user.type === 'SA'){
            let workspace = plants
            return workspace
        }
        if(user.type === 'A'){
            let workspace = plants
            return workspace
        }
        if(user.type === 'AC'){
            let workspace = plants
            return workspace
        }
        if(user.type === 'I'){
            let workspace = plants[0]
            return workspace
        }
    }

}


