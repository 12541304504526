import React,{useState} from 'react';

//components
import AddPersonsForm from '../../../components/forms/AddPersonsForm';
import FactoryAssigment from '../../../components/factoryAssigment'
import ResourceListContainer from  '../../../components/lists/ResourceListContainer'
import SidebarContainer from '../../../components/pageContainers/SidebarContainer'
import DuoContainer from '../../../components/pageContainers/DuoContainer'

//material
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fab, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles((theme) => ({
    container:{
        width:'100%',
        display:'grid',
        gridTemplateColumns:'1fr 400px',
        gap:'24px',
        height:'-webkit-fill-available',
    },
    fabBack:{
        position: 'fixed',
        left:  '270px',
        bottom: theme.spacing(2),
    },
    asignFactory:{
        display:'grid',
        gap:'24px',
        gridTemplateColumns:'1fr 1fr',
        height:'-webkit-fill-available'
    }

}));



function getSteps() {
    return ['Crea un Gerente Regional', 'Asigna Fábricas a su cargo',];
  }

  //
function getStepContent(step) {
    switch (step) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      default:
        return 'Unknown step';
    }
  }

const AddAccounManager = () => {
    const classes = useStyles()
    const theme = useTheme()
    //componentState
    const [factory, setFactory] = useState();
    const [entity, setEntity] = useState();
    const [load,setLoad] =useState({
        loading:true,
        success:true,
        error:null
    })


    //stepperstate
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();

    const refreshData = () =>{
        let ref = !load.success
        setLoad({...load,success:ref})
    }

    const handleFactory = (object) =>{
        setFactory(object)
    }
    const handleEmail = (email) =>{
        setEntity({email:email})
    }
 

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <>
                <Stepper nonLinear activeStep={activeStep} >
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton /* onClick={handleStep(index)} */ completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                    ))}
                </Stepper>
                {allStepsCompleted() ? (
                <div>
                    <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                </div>
                ) : (
                
                <>
                    {getStepContent(activeStep) === 1 &&<>

                        <SidebarContainer>

                            <ResourceListContainer //display a list of account managers
                                onLoad={<p>Todavía no hay ningún gerente regional asignado</p>}
                                endpoint={'AllAccountManagers'}
                                handleNext={handleNext}
                                handleState={setEntity}
                                searchBarTitle='Gerentes Regionales'
                            />
                            <AddPersonsForm // add account manager
                                endpoint='CreateAccountManager'
                                title={getSteps()[activeStep]} 
                                color={theme.palette.primary.main} 
                                handleNext={handleNext} 
                                handleEmail={handleEmail}
                            />

                        </SidebarContainer>
                        
                    </>}
                    {getStepContent(activeStep) === 2 && <>
                        <DuoContainer >

                            <FactoryAssigment //AsignFactories to an Account Manager
                                color={theme.palette.primary.main} 
                                handleFactory={handleFactory}
                                title={getSteps()[activeStep]} 
                                email={entity.email}
                                action={'asign'}
                                filterByEmail={['desarrollo@tgdsa.com']}
                                handleRefresh={refreshData}
                                refresh={load.success}
                                />
                            
                            <FactoryAssigment //Cancel Client accont to an Account Manager
                                color={theme.palette.primary.main} 
                                handleFactory={handleFactory}
                                title={'fabricas del manager regional'} 
                                onLoad={<p>Todavía no hay ninguna planta asignada para este usuario</p>}
                                email={entity.email}
                                action={'cancel'}
                                refresh={load.success}
                                handleRefresh={refreshData}
                                />
                            
                            </DuoContainer>
                    </> }

                    {getStepContent(activeStep) === 2 &&
                        <Fab className={classes.fabBack} color="primary" onClick={handleBack}>
                            <ArrowBackIcon />
                        </Fab>
                    }
                </>
            )}
        </>
    );
}

export default AddAccounManager;
