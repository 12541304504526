import { Button, Chip,  CircularProgress,  Icon, makeStyles, useTheme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import TgdList from '../../../components/lists/TgdList';
import TgdListToolbar from '../../../components/lists/TgdListToolbar';
import ListContainer from '../../../components/pageContainers/ListContainer';
import QueryStatus from '../../../components/QueryStatus';
import {  useUpdateDemoRequest, useDimention, useShowedDemoRequests, useHiddenDemoRequests, usePerson,useDemoRequests, useActivateDemoRequest, useDesactivateDemoUser } from '../../../services/person.service';

import { useToken } from '../../../services/session.service';
import { NavLink, Route } from 'react-router-dom';
import { calcDate, USER_ROUTES } from '../../../services/tgd.config';
import PageContainer from '../../../components/pageContainers/PageContainer';
import { htmlDemoApproval } from '../../../emailTemplates/htmlClientDemo';
import { SessionContext } from '../../../store/sessionContext';

const useStyles = makeStyles((theme) => ({
    active:{
        backgroundColor:theme.palette.primary.grey,
        margin:'0 8px',
    }
}));



const TgdChip = ({title,customColor,themeColor,action,clickable}) => {

    const [loading,setLoading] = useState(false)

    const handleAction = () => {
        setLoading(true)
        action()
    }

    if(loading) return <CircularProgress size='16px'/>
    return(
        <>
        {clickable ? 
            <Chip
            style={{backgroundColor: customColor ? customColor : '',color: customColor ?'white':'white',margin: '8px'}}
            size="small"
            label={title}
            color={themeColor}
            clickable 
            onClick={handleAction}/>
            :
            <Chip
            style={{backgroundColor: customColor ? customColor: '',color: customColor ?'white':'white',margin: '8px'}}
            size="small"
            label={title}
            color={themeColor} />
        }
     </>
     )
}


const AddDemoUserPage = () => {
    const token = useToken()
    const classes=useStyles()
    const dimention = useDimention()
    const [auth] = useContext(SessionContext)
    const [showedDemoRequests,{status:showedStatus}] = useShowedDemoRequests()
    const [hiddenDemoRequests,{status:hiddenStatus}] = useHiddenDemoRequests()
    const {refetch} = useDemoRequests()
    const {data:person} = usePerson()
    //mutations
    const [AproveDemoUser] = useActivateDemoRequest({onSuccess:refetch})
    const [ActivateDesactivateDemoUser] = useDesactivateDemoUser({onSuccess:refetch})
    const {archive,unArchive} = useUpdateDemoRequest({onSuccess:refetch})



    const CustomButtons = (item) => {



        const handleDemoUserState = (boolean) => {
            ActivateDesactivateDemoUser({
                parameters:{
                    applicantsEmail:item.applicantsEmail,
                    demoState:boolean
                },
                token
            })
        }

        const handleActivate = () => {
            if(!item.unsubscribeDate){
                AproveDemoUser({
                    parameters:{
                        name: item.applicantsName,
                        telephone: item.applicantsTelephone,
                        address: 'No ha ingresado una dirección',
                        email: item.applicantsEmail,
                        file: 0,
                        referrer: person[0].id,
                        password: `${item.applicantsTelephone}_${item.applicantsName}`,
                        tagF:'DEMO',
                        tagFP:'',
                        htmlDemoApproval:htmlDemoApproval(item),
                    },
                    token
            })  
            }else if(item.unsubscribeDate){
                handleDemoUserState(true)
            }
            
        }

        
        return(
            <>
                    {item && !item.demoState && !item.unsubscribeDate &&
                       < TgdChip
                       key={1 + item.id}
                        title={'Esperando Revisión'}
                        themeColor='primary'
                       />
                    
                    }

                    {item && item.demoState && !(parseInt(calcDate(item.unsubscribeDate)) < 0) && 
                        < TgdChip
                        key={5 + item.id}
                        title={'Activado'}
                        customColor={'green'}
                       />
                    }

                    {item && !item.demoState && (calcDate(item.unsubscribeDate) < 0) && !!item.unsubscribeDate &&
                        < TgdChip
                        key={7 + item.id}
                        title={'Vencido'}
                        customColor={'black'}
                       />
                    }
    
                    {item && !item.demoState && !item.hidden &&
                       < TgdChip
                       key={4 + item.id}
                       title={item.unsubscribeDate ? 'renovar' : 'Activar'}
                       customColor={`orange`}
                       action={handleActivate}
                       clickable={true}
                      />
                        
                    }
                    
                    

                    {item && item.demoState && !item.hidden &&
                        < TgdChip
                        key={6 + item.id}
                        title={'Desactivar'}
                        themeColor={`primary`}
                        action={()=>handleDemoUserState(false)}//desactivate
                        clickable={true}
                        />
                    }

                    {item && !item.hidden && 
                       < TgdChip
                       key={2 + item.id}
                       title={'Archivar'}
                       themeColor='secondary'
                       action={()=>archive(item.applicantsEmail)}
                       clickable={true}
                      />
                    }
    
                    {item && item.hidden &&
                        < TgdChip
                        key={3 + item.id}
                        title={'Restaurar'}
                        themeColor='secondary'
                        action={()=>unArchive(item.applicantsEmail)}
                        clickable={true}
                       />
                    }
                </> 
        )
    }
  
    return (
        <>
            <QueryStatus
                status={showedStatus}
                errorMessage={'no se han podido obtener usuarios demo'}
                children={<>
                    <ListContainer>
                        <TgdListToolbar 
                            searchBar={true}
                            title={'Solicitudes Demo'}
                            actions={<></>}
                        />
                            <PageContainer>
                                <Button type='button' exact style={{margin:'0 8px'}}activeClassName={classes.active} component={NavLink} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>Pendientes</Button>
                                <Button type='button' style={{margin:'0 8px'}}activeClassName={classes.active} component={NavLink} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}/${USER_ROUTES.RejectedDemoUsers}`}>Archivados</Button>
                            </PageContainer>
                            <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
                                <TgdList
                                    icon={'person'}
                                    status={showedStatus}
                                    items={showedDemoRequests}
                                    children={true}
                                    customButtons={CustomButtons}
                                />
                            </Route>
                            <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}/${USER_ROUTES.RejectedDemoUsers}`}>
                                <TgdList
                                    icon={'person'}
                                    status={hiddenStatus}
                                    items={hiddenDemoRequests}
                                    children={true}
                                    customButtons={CustomButtons}
                                    />
                            </Route>
                    </ListContainer>
                </>}
            />
        </>
    );
}

export default AddDemoUserPage;
