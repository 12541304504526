import { FormHelperText, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useFactoriesByUser } from '../../services/factory.service';

const useStyles = makeStyles(() => ({
      formSelect:{
          width:'100%'
      },
      formContainer:{
        display:'grid',
        justifyContent:'start', 
        gap:'16px',
        gridTemplateColumns:'100%',
        alignItems:'starts',
        margin:'24px 0 0 0',

    },
}))

const FactorySelectorForm = ({handleFactory}) => {
    const classes = useStyles()
    const [factory,setFactoy] = useState({
        tagF:'SELECCIONAR'
    })
    const [error,setError] = useState(null)
    const {data:factoriesByUser} = useFactoriesByUser()

    const handleChange = (item) =>{
        if(item.target.value === 'SELECCIONAR'){
            setError('este campo es oblicatorio')
        }
        if(!!item.target.value){
            setFactoy({tagF:item.target.value})
            handleFactory({
                name:item.target.value.tagF,
                factoryId:item.target.value.factoryId
            })
            setError(null)
        }
    }

    return (
        <div className={classes.formContainer} >
            
            <FormHelperText>Selecciona la fábrica a la cual quieres agregar una planta</FormHelperText>
            <Select
                className={classes.form}
                name='tagF'
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"

                value={factory.tagF}
                onChange={handleChange}
            >
                <MenuItem value={'SELECCIONAR'}><em>SELECCIONAR</em></MenuItem>
                {factoriesByUser &&
                factoriesByUser.map((item,index) =>
                    <MenuItem value={item} key={index}>{item.tagF}</MenuItem>
                )}
            </Select>
            {error && <FormHelperText error id="component-error-text">{error}</FormHelperText>}
        </div>
    );
}

export default FactorySelectorForm;
