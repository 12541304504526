import React,{useContext} from 'react'
//context
import {SessionContext} from '../store/sessionContext'
//material
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'



const MenuPlantSelector = (props) => {
    const [auth,setAuth] = useContext(SessionContext)
    

    const handleClick = () => {
        setAuth({
            ...auth,
            multyPlant:true,
            workspace:null
        })
     };

    return (
        <>
            <Chip
                className={props.classes}
                variant="outlined"
                style={{textTransform:'uppercase'}}
                size="small"
                avatar={null}
                label={auth.workspace.split(' ')[1]}
                clickable
                color={props.color}
                icon={<Icon className={props.icon}>more_horiz</Icon>}
                style={{margin:'0 16px',maxWidth:'200px'}}
                aria-controls="simple-menu" 
                aria-haspopup="true" 
                onClick={handleClick}
            />  
        </>       
    );   
    
}

export default MenuPlantSelector