import React from 'react';
//components
import PageContainer from '../../components/pageContainers/PageContainer'
//Router
import { Route,Switch } from 'react-router-dom';
import ConfigPage from '../ConfigPage';
import { USER_ROUTES } from '../../services/tgd.config';

const OperatorContent = ({dimention}) => {
    return (
        <PageContainer>
            <Switch>
                    <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                        <ConfigPage/>
                    </Route>
                    <Route path={`/${USER_ROUTES.home}/${dimention}`}>
                        <PageContainer> Bienvenido al Panel, muy pronto podrás gestionar tu trabajo desde la App de TGD Soft </PageContainer>
                    </Route>
            </Switch>
        </PageContainer>
    );
}

export default OperatorContent;
