import React, { useEffect, useState, useContext } from 'react';
import axios from'axios'
import { URL } from './tgd.config'
//services
import { getAllResources, getAllResourcesByEmail, getUserActivatedCancelledPlants, updateUserPlantState, assignPlantToUser } from './tgd.axios.service'
import { useQuery, useMutation } from 'react-query';
import {useToken} from './session.service'


//----------------------------
//axios Repo
export const createPlant = ({parameters,token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/CreatePlant`,
        data:parameters
    }
    return axios(config).then((res)=>{return res}).catch((err) => {return err})
}


//----------------------------
//react-query HOOKS
//---------------------------------------------
//---------------------------------------------
//QUERYS

export const useAllPersons = () => {
    const token = useToken()
    return useQuery(['AllPerson',token], getAllResources)
}
export const useAllPlants = () => {
    const token = useToken()
    return useQuery(['AllPlants',token], getAllResources)
}
export const usePlantsByFactory = (tagF) => {
    const token = useToken()
    return useQuery(['PlantsByTagF',tagF,token],getAllResourcesByEmail,{
        enabled:tagF
    })
}

export const useEngineerPlants = (email) => {
    const token = useToken()
    return useQuery(['EngineerPlants', email, token], getAllResourcesByEmail)
}
export const useOperatorPlants = (email) => {
    const token = useToken()
    return useQuery(['OperatorPlants',email, token], getAllResourcesByEmail)
}

export const useUserActivatedCancelledPlants = (email) => {
    const token = useToken()
    return useQuery(['UserActivatedCancelledPlants',email,token],getUserActivatedCancelledPlants,{
        enabled:!!email
    })
}

export const useUserActivatedPlants = (email) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter(item => item.state ==='A') : null
    if(dataProcesed) return [dataProcesed, query]
    return [dataProcesed, query]
}

export const useUserCancelledPlants = (email) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter(item => item.state ==='C') : null 
    if(dataProcesed) return [dataProcesed, query]
    return [dataProcesed, query]
}




//------------------------------
//MUTATIONS

export const useUpdatePlantsStateFromUser = (refetch) =>{//update plant states for user plants state endpoint: /UpdatePlant
    return useMutation(updateUserPlantState,{
        onSuccess:refetch
    })
}

export const useAssignPlant = (refetch) => {
    return useMutation(assignPlantToUser,{
        onSuccess:refetch
    })
}

export const useCreatPlant = (config) => {
    return useMutation(createPlant,config)
}

//client assigment

/* export const assignClientPlant = () => {
    return useMutation(assignPlantsClient)
}
 */

//----------------------------
//custom HOOKS
//---------------------------------------------


export const useAssignCancelUserPlant = (email) => {

    const {data:userActiveCanceledPlants,refetch} = useUserActivatedCancelledPlants(email)
    const token = useToken()
    const [updatePlantState] = useUpdatePlantsStateFromUser(refetch)//useMutation
    const [assignPlantToUser] = useAssignPlant(refetch)//useMutation
    const [userCanceledPlants] = useUserCancelledPlants(email)
    const [userActivePlants,isFetching] = useUserActivatedPlants(email)



    const handleUserPlants = (action,parameters) => {
        if(action==='assign'){
            if(userCanceledPlants){
                if((userCanceledPlants.filter(i => i.tagFP === parameters.tagFP).length > 0)){
                    /* console.log('user's plant activation') */
                    updatePlantState({parameters,token})
                }
                else{
                   /*  console.log('assign plant to an user when plant doesn´t exist for the user') */
                    assignPlantToUser({parameters,token})
                }
            }
            else{
                /* console.log('user's plant activation') */
                updatePlantState({parameters,token})
            }
        }
        if(action === 'cancel'){
            /* console.log('user's plant cancelation') */
            updatePlantState({parameters,token})
        }
    }


    return [handleUserPlants]
}

