import React,{useState,useContext, useEffect} from 'react';

//material
import {makeStyles} from '@material-ui/core/styles'
import { Box, Button, CircularProgress, Divider, Grid, Icon, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DomainIcon from '@material-ui/icons/Domain';
import { UserContext } from '../../store/userContext';
import { useUpdatePerson, useUser } from '../../services/person.service';
import { useHistory, useLocation } from 'react-router-dom';
import { RouterSharp } from '@material-ui/icons';
import { USER_ROUTES } from '../../services/tgd.config';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useToken } from '../../services/session.service';
import { Alert } from '@material-ui/lab';
import PageContainer from '../pageContainers/PageContainer';

const UpdatePersonForm = ({person}) =>{
    const [mutate,{status}] = useUpdatePerson()
    const token = useToken()
    const [newPersonData,setnewPersonData] = useState({
        password:'',
        newPassword:''
    })

    
    const handleChange = (e) =>{
        setnewPersonData({...newPersonData,[e.target.name]:e.target.value})
    }
    const handleSubmit = () => {
        let body={
            email:person.email,
            password:newPersonData.password
        }
        mutate({
            body,
            token
        })
    }


    
    useEffect(() => {
        // custom form rule for have < 6 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isPasswordLength')) {
            ValidatorForm.addValidationRule('isPasswordLength', (value) => {
                if (value.length < 6 ) {
                    return false;
                }
                return true;
            });
        }
        // custom form rule for have < 6 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isPasswordCorrect')) {
            ValidatorForm.addValidationRule('isPasswordCorrect', (value) => {
                if (value !== sessionStorage.getItem('passwordConfirmation')) {
                    return false;
                }
                return true;
            });
        }
    }, [newPersonData]);

    if(status === 'loading')return <CircularProgress/>

    return(
    <>
    <div style={{padding:'24px',marginBottom:'16px', border:'solid 1px #d3d3d3', width: '100%'}}>
        {status === 'success'? <><Alert severity="success">Usuario Actualizado Correctamente</Alert><br/></> : null}
        
        <br/>
        <Typography variant={'button'}>Cambiar Contraseña</Typography>
        <Grid container spacing={3} style={{width:'100%'}}>
            <Grid item md={3}>
            <ValidatorForm  onSubmit={handleSubmit}>
                            <TextValidator
                                /* className={classes.form} */
                                label="Nueva Contraseña"
                                onChange={handleChange}
                                name="password"
                                onFocus={sessionStorage.setItem('passwordConfirmation',newPersonData.password)}
                                value={newPersonData.password}
                                validators={['required', 'isPasswordLength']}
                                errorMessages={['Este campo es requerido', 'Este campo no puede contener menos de 6 carácteres']}
                            />
                            <br/>
                            <TextValidator
                                /* className={classes.form} */
                                label="Repite la Contraseña"
                                onChange={handleChange}
                                name="newPassword"
                                value={newPersonData.newPassword}
                                validators={['required', 'isPasswordCorrect']}
                                errorMessages={['Este campo es requerido','Las contraseñas no coinciden']}
                            />
                            <br/>
                            <Button type='submit' variant='contained' color='secondary'> Guardar </Button>
                </ValidatorForm>
            </Grid>
            <Grid item md={9}>
                    <ListItem>
                        <ListItemIcon><Icon color={'secondary'}>person</Icon></ListItemIcon>
                        <Typography variant={'button'}>{person && person.name}</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Icon color={'secondary'}>email</Icon></ListItemIcon>
                        <Typography variant={'button'}>{person && person.email}</Typography>
                    </ListItem>
            </Grid>
           
        </Grid>
    </div>
    </>
    )
}

export default UpdatePersonForm
