import React, { useContext, useEffect, useState } from 'react';
//components
import PageContainer from '../components/pageContainers/PageContainer'
import ResourceList from '../components/lists/ResourceList';
//services
import { useUnReadedNotifications, useReadUnreadNotification, useReadedNotifications, useAddNotification } from '../services/notifications.service';

//material
import {makeStyles} from '@material-ui/core/styles'
import { Paper,Typography,Toolbar, Divider, CircularProgress, InputLabel, Select, MenuItem, Icon, InputAdornment } from '@material-ui/core';
import { Button } from '@material-ui/core';
import DraftsIcon from '@material-ui/icons/Drafts';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import IconButton from '@material-ui/core/IconButton';
import { useUser } from '../services/person.service';
import { useToken } from '../services/session.service';
import TgdModal from '../components/TgdModal';
import { useLocation } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Alert } from '@material-ui/lab';
import { useFactoriesByUser } from '../services/factory.service';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    HeaaderNotifPage:{   
        paddingTop:theme.spacing(0),
        paddingLeft:theme.spacing(1),
        paddingBottom:theme.spacing(0),
        paddingRight:theme.spacing(0),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width:'auto'
      },
      form:{
          width:'100%'
      }

}))

const AddNotificationForm = () => {
        const classes = useStyles()
        const [mutate,{status}] = useAddNotification()
        const token = useToken()
        const {data:factoriesByUser} = useFactoriesByUser()
        const nowDate = () => {
            let date = new Date()
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
    
            if(month < 10){
            return `${day}-0${month}-${year}`
            }else{
            return `${day}-${month}-${year}`
            }
        }

        const [newNotification,setNewNotification] = useState({
            title:'',
            body:'',
            global:false,
            tagF:'',
            tagFP:'',//void for now
            releaseDate:nowDate(),
            readingLevel:4
        })
        
        
        
        
        const handleChange = (e) =>{
            setNewNotification({...newNotification,[e.target.name]:e.target.value})
        }

        const handleDate = (e) =>{
            let badDateFormat = e.target.value.split('-')
            let date = `${badDateFormat[2]}-${badDateFormat[1]}-${badDateFormat[0]}`
            setNewNotification({...newNotification,[e.target.name]:date})
            
        }

        const handleSubmit = () => {
            mutate({
                notification:newNotification,
                token
            })
        }
    
    
        
        useEffect(() => {
            // custom form rule for have < 6 caracters on 'isPasswordLength'
            if (!ValidatorForm.hasValidationRule('isPasswordLength')) {
                ValidatorForm.addValidationRule('isPasswordLength', (value) => {
                    if (value.length < 6 ) {
                        return false;
                    }
                    return true;
                });
            }
            // custom form rule for have < 6 caracters on 'isPasswordLength'
            if (!ValidatorForm.hasValidationRule('isPasswordCorrect')) {
                ValidatorForm.addValidationRule('isPasswordCorrect', (value) => {
                    if (value !== sessionStorage.getItem('passwordConfirmation')) {
                        return false;
                    }
                    return true;
                });
            }
        }, [newNotification]);
    
        if(status === 'loading')return <CircularProgress/>
    
        return(
        <>
        <div style={{borderTop:'solid 1px #d3d3d3',padding:'24px 0',marginBottom:'16px', borderBottom:'solid 1px #d3d3d3',boxSizing:'border-box', width: '100%'}}>
            {status === 'error'? <Alert severity="danger">Un error ha ocurrido, intentalo nuevamente</Alert> : null}
            {status === 'success'? <Alert severity="success">Notificacion Creada!</Alert> : null}
            <br/>
            <Typography  variant='h5'>Nueva Notificación</Typography>
            <br/>
            <ValidatorForm className={classes.formControl} onSubmit={handleSubmit}>
                        <div style={{display:'flex',gap:'24px'}}>
                            <TextValidator
                                className={classes.form}
                                label="Titulo"
                                onChange={handleChange}
                                name="title"
                                value={newNotification.title}
                                validators={['required']}
                                errorMessages={['Este campo es requerido']}
                            />

                            <div /* className={classes.formControl} */>
                                <InputLabel id="demo-simple-select-label">Selecciona una Fábrica</InputLabel>
                                <Select
                                    className={classes.form}
                                    name='tagF'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newNotification.tagF}
                                    onChange={(e)=>handleChange(e)}
                                    label="Selecciona una Fábrica"
                                >

                                    {factoriesByUser &&
                                    factoriesByUser.map((item,index) =>
                                        <MenuItem value={item.tagF} key={index}>{item.tagF}</MenuItem>
                                    )}

                                </Select>
                            </div>
                            <TextValidator
                                type='date'
                                className={classes.form}
                                label="fecha de publicación"
                                onChange={handleDate}
                                name="releaseDate"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">|</InputAdornment>,
                                }}
                            />
                        </div>
                       
                        <br/>
                        <TextValidator
                            className={classes.form}
                            multiline
                            rowsMax={20}
                            label="Mensaje"
                            onChange={handleChange}
                            name="body"
                            value={newNotification.body}
                            validators={['required']}
                            errorMessages={['Este campo es requerido']}
                        />
                        <br/>
                        
                        <Button type='submit' variant='contained' color='secondary'> Enviar </Button>
    
            </ValidatorForm>
        </div>
    </>
    )
}


const NotificationsPage = () => {

    //state and querys
    const classes = useStyles()
    const token = useToken()
    const {data:user} = useUser()
    const {data:notifications} = useUnReadedNotifications()
    const {data:readedNotifications} = useReadedNotifications()
    
    //Actions
    const [readOne,unReadOne,readAll] = useReadUnreadNotification()
    const [open,setOpen] = useState(false) 

    const handleModal= () => {
        setOpen(!open)
    }
    //Component to pass data
    const UnreadButton = (item) => {
        return(
            <>
                <IconButton key={'lisIconChild2'} onClick={()=>unReadOne(item)} variant="outlined" color="primary"> <MarkunreadIcon /> </IconButton>
            </>
            )
    }
    const ReadButton = (item) =>{
        return (
        <>
            <IconButton key={'lisIconChild'} onClick={()=>readOne(item)} variant="outlined" color="secondary" > <DraftsIcon /> </IconButton>
        </>
        )
    }

    const toggleButton = <Button variant="outlined" color="secondary" >Ver</Button>
    return (
        <>
            <PageContainer>
                <div>
                    <Toolbar style={{display:'flex', justifyContent:'space-between'}} className={classes.HeaaderNotifPage} >
                        <Typography variant='h5' >
                            Notificaciones
                        </Typography>
                        <div>

                            {user && (user.type !== 'C') && (user.type !== 'I') && (user.type !== 'O') &&
                                <Button 
                                style={{margin:'0 16px'}}
                                onClick={handleModal}
                                variant="contained"
                                color="secondary"
                                startIcon={<AddAlertIcon />}>Nueva Notificación</Button>
                            }
                            
                            <Button 
                            onClick={readAll}
                            variant="contained"
                            color="secondary"
                            startIcon={<DraftsIcon />}>Leer Todo</Button>
                            
                        </div>
                    </Toolbar>
                </div>
                <br/>
                
                {notifications && readedNotifications && (notifications.length === 0) && (readedNotifications.length === 0) &&
                    
                    <>
                    <Paper style={{height:'64px',padding:'24px'}}>
                        <Typography>
                            Su bandeja de Notificaciones está Vacía!
                        </Typography>
                    </Paper> 
                    <br/><br/>
                    </>
                }

                {notifications && 
                <>
                <Typography>
                    Sin leer
                </Typography>
                <br/>
                    {notifications.map((item,index) =>(
                        <ResourceList
                        key={index}                       
                        state={item}
                        handleState={readOne}
                        children={ReadButton}
                        toggleButton={<ExpandMoreIcon/>}
                        actionEdit={ () => readOne(item)}
                        />
                    ))}</>}

                    <Divider variant="middle"/>
                    <br/>

                    {readedNotifications &&
                    <>
                    <Typography>
                        Leído
                    </Typography>
                    <br/>
                    {readedNotifications.map((item,index) =>(
                        <ResourceList
                        background='#ffffff66'
                        style={{opacity:'50%'}}
                        key={index}                       
                        state={item}
                        handleState={readOne}
                        children={UnreadButton}
                        toggleButton={<ExpandMoreIcon/>}
                        actionEdit={ () => readOne(item)}
                        />
                    ))}</>}
            </PageContainer>
            <TgdModal
                open={open}
                handleClose={handleModal}
            >
                <AddNotificationForm />
            </TgdModal>
        </>
    );
}       

export default NotificationsPage;
