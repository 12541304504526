import React from 'react';
//material
import {Button, Dialog, DialogActions, DialogContent, IconButton, Paper, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const TgdModal = ({open,handleClose,children,title,fullWidth,fullScreen,footerActions,defaultButton}) => {

    return (
            <Dialog fullWidth={fullWidth} fullScreen={fullScreen} onClose={handleClose} /* aria-labelledby="customized-dialog-title" */ maxWidth='md' open={open} >
                {title && 
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                    </DialogTitle>
                }
                <DialogContent dividers>
                    {children}
                </DialogContent>
                <DialogActions><>
                    {footerActions && footerActions}
                    {defaultButton 
                    ? 	defaultButton
                    :	<Button autoFocus onClick={handleClose} variant="outlined">
                        Cerrar
                      </Button>}
                    </>
                </DialogActions>
            </Dialog>
    )
}

export default TgdModal;
