import React,{useContext} from 'react';
//material
import {Button} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
//Context
import {SessionContext} from '../../store/sessionContext'
import { useHistory } from 'react-router-dom';
//service
import {URL} from '../../services/tgd.config'


const LogOutButton = ({width,title}) => {

    const [auth,setAuth] = useContext(SessionContext)
    const history = useHistory()
    /* const [user,setUser] = useContext(UserContext) */
    /* const [pbi,setPbi] = useContext(PbiContext) */
    //ACTIONS

    const handleLogout = ()=>{
        localStorage.clear()
        sessionStorage.clear()
        history.replace(URL)
        /* setPbi({}) */
        /* etUser({}) */
        setAuth({}) 
    }

    return (
        <Button  onClick={handleLogout} startIcon={<ExitToAppIcon />} color='primary' variant='contained' style={{width: width ? width:'100%',fontSize:'10px'}}>{title}</Button>
    );
}

export default LogOutButton
