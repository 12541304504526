import React, { useState } from 'react';
//material
import {  Icon, IconButton, useTheme } from '@material-ui/core';
//components
import AddPersonsForm from '../../../components/forms/AddPersonsForm'
import UserList from '../../../components/lists/EntityList';
import SidebarContainer from '../../../components/pageContainers/SidebarContainer';
import TgdModal from '../../../components/TgdModal'
import DuoContainer from '../../../components/pageContainers/DuoContainer';
import AssignCancelPlantsRefactor from '../../../components/AssignCancelPlantsRefactor';
//services
import { useOperatorsByReferer, useUser } from '../../../services/person.service';


const AddOperatorRefactor = () => {
    const theme = useTheme()
    const {data:user} = useUser()
    const [operators,{status:operatorStatus}] = useOperatorsByReferer()
    const [open,setOpen] = useState(false)
    const [focusPersonData,setFocusPersonData] = useState()

    const handleModal = () =>{
        setOpen(!open)
    }

    const newPersonEmail = (e) => {
        console.log(e)
        setFocusPersonData({email:e})
    }

    const assignPlants = (item) => {
        handleModal()
        setFocusPersonData(item)
    }

    const customButtons = (item) => {
        if(user && (user.type !== 'I' ) && (user.type !== 'O')){
            return(
                <IconButton style={{margin:'0 0'}} variant='outlined' edge="end" aria-label="Asignar Plantas" onClick={()=>assignPlants(item)} color={'primary'}>
                    <Icon>business</Icon>
                </IconButton > 
            )
        }
    }

    return (
        <>
            <SidebarContainer>
                <UserList
                    title='Operadores'
                    icon={'person'}
                    status={operatorStatus}
                    items={operators}
                    customButtons={customButtons}
                    />
                <AddPersonsForm
                    color={theme.palette.primary.main}
                    handleEmail={newPersonEmail}
                    handleNext={handleModal}
                    title={'Agrega un Operario'}
                    endpoint={'CreateOperator'}
                    />
            </SidebarContainer>
            
            
            <TgdModal title={'Asigna o cancela plantas de este usuario'} open={open} handleClose={handleModal} >
                <DuoContainer>
                    <AssignCancelPlantsRefactor 
                    action='assign' 
                    roleToEdit={'O'}
                    title={'Click para asignar'}
                    userData={focusPersonData} 
                    />

                    <AssignCancelPlantsRefactor 
                    userData={focusPersonData} 
                    roleToEdit={'O'} 
                    action={'cancel'} 
                    title={'click para cancelar'}
                    />
                </DuoContainer>
            </TgdModal>
            
        </>
    );
}

export default AddOperatorRefactor;
