
import axios from'axios'

//services
import { URL } from './tgd.config'



//--------------------------------------
//AXIOS REPOSITORY
//---------------------------------------------

export const getAuth = (data) => {
    let res = axios.post(`${URL}/authenticate`, data, null)
    .then(res => {
        sessionStorage.setItem('token',JSON.stringify(res.data.token))
        return res.data.token
    }).catch()
    return res
}

export const  getTokenVerification = (endpoint,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`
    }

    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const  getAllResources = (endpoint,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const  getAllResourcesByEmail = (endpoint,email,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url:`${URL}/${endpoint}/${email}`
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const getUserActivatedCancelledPlants = (_,email,token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/UserPlantsState/${email}`,
    }
    let res = axios(config).then(res => {return res.data}).then(res => {return res})
    return res
}

export const assignPlantToUser = ({parameters,token}) => { //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/AssignPlant`,
        data: parameters
    }
    let res = axios(config).then(res=>{return res.data}).catch(res=>{return res})
    return res
}


export const updateUserPlantState = ({parameters,token}) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/UpdatePlant`,
        data:parameters
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}




