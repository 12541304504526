import React,{useContext} from 'react';
//powerBi
import { Report } from 'powerbi-report-component'
//router
import {Route} from 'react-router-dom'
//context
import { PbiContext } from '../../store/pbiContext'
import { UserContext } from '../../store/userContext';
import { useWorkspace } from '../../services/person.service';
import { useAadToken, usePbiReportsInGroup } from '../../services/pbi.service';
import Loading from '../presentational/Loading';
import { USER_ROUTES } from '../../services/tgd.config';

const Reports = () => {
    const workspace = useWorkspace()
    const aadToken = useAadToken()
    const reports = usePbiReportsInGroup()

    if(reports){
        return (

            <>
            {reports.map((report,index)=>
                <Route key={index} exact path={`/${USER_ROUTES.home}/${workspace}/${report.name}`}>
                    <Report
                        tokenType='Aad'   //"Embed" // "Aad"
                        accessToken={aadToken} // accessToken goes here
                        embedUrl={report.embedUrl} // embedUrl goes here
                        embedId={report.id} // report or dashboard Id goes here
                        pageName="" // set as current page of the report
                        reportMode="View" // open report in a particular mode View/Edit/Create
                        datasetId={report.datasetId} // required for reportMode = "Create" and optional for dynamic databinding in `report` on `View` mode
                        /*  extraSettings={extraSettings} */
                        permissions="All" // View, For "Edit" mode permissions should be "All"
                        style={{
                        height:'100vh',
                        width:'100%',
                        margin:'0px',
                        border:'none',
                        padding:'0px'
                        }}
                        /* onLoad={this.handleReportLoad} */
                        /* onRender={this.handleReportRender} // not allowed in "Create" mode
                        onSelectData={this.handleDataSelected}
                        onPageChange={this.handlePageChange}
                        onTileClicked={this.handleTileClicked}
                        onSave={this.handleReportSave} */ // works for "Edit" and "Create"
                        />
                </Route>
            )} 
            </>
        );
    }
    return(Loading)
}

export default Reports;
