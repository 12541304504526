import React, { useState } from 'react';

//Materia
import { Icon, IconButton, useTheme } from '@material-ui/core';

//components
import AddPersonsForm from '../../../components/forms/AddPersonsForm'
import UserList from '../../../components/lists/EntityList';
import SidebarContainer from '../../../components/pageContainers/SidebarContainer';


//services
import { useAdministators } from '../../../services/person.service';


const AddAdminPageRefactor = () => {
    const theme = useTheme()
    const [admins,{status:adminStatus}] = useAdministators()
    const [open,setOpen] = useState(false)
    const [focusPersonData,setFocusPersonData] = useState()

    const handleModal = () =>{
        setOpen(!open)
    }

    const newPersonEmail = (e) => {
        console.log(e)
        setFocusPersonData({email:e})
    }

    const assignPlants = (item) => {
        handleModal()
        sessionStorage.setItem('editUserEmail',item.email)
        setFocusPersonData(item)
    }

    const customButtons = (item) => {
        if(false){
            return(
                <IconButton style={{margin:'0 0'}} variant='outlined' edge="end" aria-label="Asignar Plantas" onClick={()=>assignPlants(item)} color={'primary'}>
                <Icon>business</Icon>
            </IconButton > 
            )
        }
    }

    return (
        <>
            <SidebarContainer>
                <UserList
                    title='Administradores'
                    icon={'person'}
                    status={adminStatus}
                    items={admins}
                    customButtons={customButtons}
                    />
                <AddPersonsForm
                    color={theme.palette.primary.main}
                    handleEmail={newPersonEmail}
                    handleNext={handleModal}
                    title={'Agrega un Cliente'}
                    endpoint={'CreateAdmin'}
                    />
            </SidebarContainer>
            
        </>
    );
}

export default AddAdminPageRefactor;
