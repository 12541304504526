import React,{useState} from 'react'
//Components

import EaseInOut from '../components/presentational/EaseInOut'
//context

//material
import { makeStyles} from '@material-ui/core/styles'
import NavBar from './navComponents/NavBar'
import Sidebar from './navComponents/Sidebar'
import { Hidden } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline';


let drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
         ...theme.mixins.toolbar,
    },
    dashLayoutContent: {
        display:'flex',
        flexGrow:1,
        flexDirection:'column',
        padding: theme.spacing(0),
        backgroundColor:theme.palette.background.customBody,
        minHeight: '100vh',

      },
}));

const DashLayout = ({ children }) => {


    //MATERIAL STYLES && ACTIONS
    const classes = useStyles();
    const [open, setOpen] = useState(false);


    //ACTIONS
    const handleToggle = () => {
        setOpen(!open);
    };

   
    return (
        <EaseInOut children={
            <div className={classes.root}>
                <CssBaseline />
                {/* Header */}
                <NavBar action={handleToggle} className={classes.appBar}/>

                {/* sidebar */}
                <Hidden smDown>
                    <Sidebar
                        variant="permanent"
                        open={!open}
                        onClose={null}
                        anchor='left'
                        /* menu={props.userData.menu} */
                    />
                </Hidden>
                <Hidden mdUp>
                    <Sidebar
                        variant="temporary"
                        open={open}
                        onClose={handleToggle}
                        anchor='right'
                        /* menu={props.userData.menu} */
                    />
                </Hidden>

                {/* CONTENIDO */}
                <main className={classes.dashLayoutContent}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
            }/>
    );


}

export default DashLayout
