import React,{useEffect, useContext} from 'react';

//services
import { useUnReadedNotifications, useReadUnreadNotification } from '../../services/notifications.service';

//Material notifications
import NotificationsTwoToneIcon from '@material-ui/icons/NotificationsTwoTone';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useDimention, useWorkspace, useUser } from '../../services/person.service';
import DraftsIcon from '@material-ui/icons/Drafts';
import { ListItem } from '@material-ui/core';




const useStyles = makeStyles((theme) => ({
    toggleNotifications:{
        '&:hover':{
            backgroundColor:'#f6f6f6',
        }
    }
  }));

const NotificationsNavBar = ({color}) => {

    //state //Cache // 
    const classes = useStyles()
    const {data:user} = useUser()
    const {data:notifications} = useUnReadedNotifications()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const history = useHistory()
    const dimention = useDimention()
    const workspace = useWorkspace()

    
    //Actions
    const [readOne] = useReadUnreadNotification()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    }
    
        return (
            <>
                
                <IconButton onClick={handleClick}>
                    <Badge  color="primary" badgeContent={notifications ? notifications.length : null} >
                        <NotificationsTwoToneIcon style={{color:color}} />
                    </Badge>
                </IconButton>

                {(notifications) && (notifications.length > 0) &&
                
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    style={{
                        top:'32px',
                        right:'24px'
                    }}
                >
                        {notifications.map((item,index) =>
                            <div key={index}>
                                <MenuItem onClick={handleClose} className={classes.toggleNotifications} >
                                    <div style={{display:'flex',flexDirection:'column',width:'350px',justifyContent:'flex-start',padding:'0 0 10px'}}>
                                        <Typography display='initial' variant="h6" component="h5"><strong style={{fontSize:'16px'}} >{item.title}</strong></Typography>
                                        <Typography display='initial' variant="subtitle1" component="p" style={{fontSize:'12px', whiteSpace: 'pre-line'}}>{item.body}</Typography>
                                    </div>
                                </MenuItem>
                                <ListItem>
                                <IconButton style ={{marginLeft:'16px'}} key={'lisIconChild'} onClick={()=>readOne(item)} variant="outlined" color="secondary" > <DraftsIcon /> </IconButton>
                                <p>Marcar como leído</p>

                                </ListItem>
                                <Divider/>
                            </div>
                        )}
                    
                    {/* <MenuItem onClick={handleClose} className={classes.toggleNotifications}>
                        <div style={{display:'flex',flexDirection:'column',maxWidth:'350px',justifyContent:'flex-start',padding:'0 0 10px'}}>
                                <Typography display='initial' variant="h6" component="h5"><strong style={{fontSize:'16px'}} >Nuevo panel habilitado</strong></Typography>
                                <Typography display='initial' variant="subtitle1" component="p" style={{fontSize:'12px', whiteSpace: 'pre-line'}}>Un nuevo panel será habilitado el 20/20/20 para poder visualizar las rutas disponibles </Typography>
                        </div>
                    </MenuItem> */}
                
                </Menu>
                }
            </>
        );
    
}

export default NotificationsNavBar;
