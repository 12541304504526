import React from 'react';
//components
import ConfigPage from '../ConfigPage'
import PageContainer from '../../components/pageContainers/PageContainer';
//components
import AddUser from '../AddPages/AddUser';
//Router
import {  Route, Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../services/tgd.config';
import NotificationsPage from '../NotificationsPage';
import HomePage from '../HomePage';
import FactoryList from '../../components/lists/FactoryList';
import PlantsList from '../../components/lists/PlantsList';
import AddDemoUserPage from '../AddPages/User/AddDemoUserPage';






const SuperAdminContent = ({dimention}) => {
    
    return (
        <PageContainer>
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
                <AddDemoUserPage />
            </Route>
            <Switch>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}><AddUser /></Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.notifications}`}>
                    <NotificationsPage />
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.factories}`}>
                    <FactoryList/>
                </Route>
                
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.plants}`}>
                    <PlantsList/>
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>
                <Route exact path={`/${USER_ROUTES.home}/${dimention}`}>
                    <HomePage dimention ={dimention}/>
                    <NotificationsPage />
                </Route>
            </Switch>
            
        </PageContainer>
    );
}

export default SuperAdminContent;