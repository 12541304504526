import React,{useState,useContext, useEffect} from 'react';

//Serices
/* import {createPlant,getAllPlants} from '../../services/axiosTgd'
 */import { useAadToken, useAddUserInPbiGroup, usePbiGroups } from '../../services/pbi.service'
import {createPbiGroup} from '../../services/axiosPbi'

//material
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { CircularProgress, Paper } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { PbiContext } from '../../store/pbiContext';
import { useToken } from '../../services/session.service';
import { useAssignCancelFactory } from '../../services/factory.service';
import { useUser } from '../../services/person.service';
import { useAllPlants, useCreatPlant, usePlantsByFactory } from '../../services/plant.service';



const useStyles = makeStyles((theme) => ({
    componentContainer:{
        position:"relative",
        padding:'24px',
        margin:'24px 0 0 0',
        height:'min-content'
    },
    formContainer:{
        display:'grid',
        justifyContent:'start', 
        gap:'16px',
        gridTemplateColumns:'100%',
        alignItems:'starts',
        margin:'24px 0 0 0',
    },
    fabAdd: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));


const AddPlantFormRefactor = ({color,selectByFactory,handlePlant}) => {
    const classes = useStyles()
    const token = useToken()
    const [load,setLoad] = useState({
        loading:null,
        success:false,
        error:'',
    })
    const {data:plants,refetch} = usePlantsByFactory(selectByFactory && selectByFactory.name)
    const [createPlant] = useCreatPlant()

    const [newPlant,setNewPlant] = useState({
        location:'',
        name:'',
        factoryId:'',
        factoryName:'',
        referrer:'desarrollo@tgdsa.com'
    })
    const pbiToken = useAadToken()
    const {data:pbiGroups,refetch:refetchPbiGroups} = usePbiGroups()


    useEffect(() => {
        if(selectByFactory){
            setNewPlant({...newPlant,factoryId:selectByFactory.factoryId,factoryName:selectByFactory.name})
        }
    }, [selectByFactory]);


    //Catch form values
    const handleChange = (e) => {
        setNewPlant({...newPlant,[e.target.name]:e.target.value})
    };
   

    //varify forms and create Factory
    const sendPlant = () => {
        
            setLoad({...load,loading:true})
            let name = newPlant.name.toUpperCase()
            let count = []
            if(plants){
                count.push(plants.filter(f => f.name.toUpperCase() === name).length)
            }
            if(count > 0){
                
                setLoad({...load,error:'Este nombre ya existe'})
            }else{    
                if((newPlant.name != '') && (newPlant.name != ' ') && (newPlant.name!= '  ') && (newPlant.name != '   ')){
                    createPlant({parameters:newPlant, token})
                    .then(()=>{
                        createPbiGroup(pbiToken,`${selectByFactory.name} ${newPlant.name}`)//create new group on pbi
                        .then(()=>{
                            refetchPbiGroups()
                            setLoad({...load,loading:false,success:true,error:''})
                        })
                        .then(res => {
                            refetch()
                            setLoad({...load,loading:false,success:true,error:''})
                            console.log(res)
                        })
                        .catch(err => alert('No se ha podido crear un espacio de trabajo en Power Bi,comuniquese con soporte.'))
                    })
                    .catch(err => {
                        setLoad({...load,success:false,error:err})
                    })
                }else{
                    setLoad({...load,error:'El nombre de la fábrica no puede ir vacío'})
                }
            }
    }

    //Reset Form
    useEffect(() =>{
        if(load.success){
            setNewPlant({
                location:'',
                name:'',
                factoryId:selectByFactory.factoryId,
                factoryName:selectByFactory.name,
                referrer:'desarrollo@tgdsa.com'

            })
        }
    },[load.success])



    return (
        <Paper className={classes.componentContainer} style={{borderTop:`4px solid ${color}`}}>
            <Toolbar style={{justifyContent:'space-between',padding:'8px'}}>
                <Typography variant='button' component={'span'}>Agrega una nueva planta</Typography>
            </Toolbar>
            <div className={classes.formContainer}>
                {load.error 
                ?   <FormControl  >
                        <InputLabel htmlFor="component-error">Nombre o ciudad de la planta</InputLabel>
                        <Input
                            id="component-error"
                            value={newPlant.name}
                            name='name'
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                        />
                        <FormHelperText error id="component-error-text">{load.error}</FormHelperText>
                    </FormControl> 
                :   <FormControl >
                        <InputLabel htmlFor="component-error">Nombre o ciudad de la planta</InputLabel>
                        <Input
                            id="component-success"
                            value={newPlant.name}
                            name='name'
                            onChange={handleChange}
                            aria-describedby=""//component-error-text
                        />
                        <FormHelperText id="component-error-text"></FormHelperText>
                    </FormControl> }
                
                <FormControl disabled >
                    <InputLabel htmlFor="component-error">Id de fábrica</InputLabel>
                    <Input
                        id="component-error"
                        value={newPlant.factoryId}
                        name='factoryId'
                        onChange={handleChange}
                        aria-describedby=""//component-error-text
                    />
                    <FormHelperText id="component-error-text"></FormHelperText>
                </FormControl>
                
                <FormControl disabled>
                    <InputLabel htmlFor="component-error">Fábrica</InputLabel>
                    <Input
                        id="component-error"
                        value={newPlant.factoryName}
                        name='tagF'
                        onChange={handleChange}
                        aria-describedby=""//component-error-text
                    />
                    <FormHelperText id="component-error-text"></FormHelperText>
                </FormControl>

                <FormControl >
                    <InputLabel htmlFor="component-error">Dirección</InputLabel>
                    <Input
                        id="component-error"
                        value={newPlant.location}
                        name='location'
                        onChange={handleChange}
                        aria-describedby=""//component-error-text
                    />
                    <FormHelperText id="component-error-text"></FormHelperText>
                </FormControl>
            </div>
            <Fab onClick={()=>sendPlant()} color="primary" className={classes.fabAdd} aria-label="add">
               {load.loading? <CircularProgress color='light'/> : <AddIcon />}
            </Fab>
        </Paper>
    );
}

export default AddPlantFormRefactor;
