import React, {useState,useContext,useEffect} from 'react';
//context
import {SessionContext} from './sessionContext'
//SERVICES
import { useGlobalUserData, useUser, usePlants, usePerson } from '../services/person.service';
//data
import clientData from '../data/CLientData.json'
import adminData from '../data/AdminData.json'
import accountManagerData from '../data/AccountManager.json'
import operatorData from '../data/OperatorData.json'
import engineerData from '../data/EngineerData.json'
import superAdminData from '../data/SuperAdminData.json'



export const UserContext = React.createContext()

//Making provider component to pass states 
export const UserContextProvider = (props) => {


  const [userData,setUserData] = useState(null)
  const [user, setUser] = useState(()=>{
    const sessionData = sessionStorage.getItem('user')
    return sessionData ? JSON.parse(sessionData) : {}
  })

  //react query
  const userD = useUser()
  const plantsD = usePlants()
  const person = usePerson()

  useEffect(() => {
    sessionStorage.setItem('user',JSON.stringify({

      multiPlant:false,
      menu:null,
      PbiMenu:null,
      workspace:null,
      dimention:null,
      user:userD.data,
      plants:null,
      person:null

      }))
  }, [user]);

  useEffect(()=>{
    if((userD.data) && (person.data)){
        
        setUser({...user,person:person.data[0], multiPlant:false})
    }
    if(plantsD.data){
      setUser({...user,plants:plantsD.data})
    }
  },[userD.data,plantsD.data,person.data])


useEffect(()=>{
    if((user.user) && (user.plants != 0) && (user.plants)){

      if((userD.data.type === 'C')){
        if((plantsD.length < 2)){
          let factory = plantsD.factory
          let plant = plantsD.name
          let workspace = factory + ' ' + plant
          sessionStorage.setItem('workspace',workspace)
          setUser({
            ...user,
            dimention:sessionStorage.getItem('workspace'),
            workspace:sessionStorage.getItem('workspace')
          })
        }
      }

      if(userD.data.type === 'I'){
        let workspace = plantsD
        sessionStorage.setItem('workspace',JSON.stringify(workspace))
        setUser({
          ...user,
          workspace:JSON.parse(sessionStorage.getItem('workspace'))
        })
        
      }

    }

    setUserData({
      superAdminData:superAdminData.menu,
      accountManagerData:accountManagerData.menu,
      adminData:adminData.menu,
      engineerData:engineerData.menu,
      clientData:clientData.menu,
      operatorData:operatorData.menu  
    })
    
  },[user.plants,userD.isSuccess])


useEffect(()=>{/*Set Menu for user*/
    if ((user.user)&& (userData != null)){
      switch (user.user.type) {

          case 'SA':
            setUser({
              ...user,
              menu:userData.superAdminData,
              dimention:'super-administrator',
            })
            break

          case 'AC':
            setUser({
              ...user,
              menu:userData.accountManagerData,
              dimention:'gerente-regional',
            })
            break;

          case 'A':
            setUser({
              ...user,
              menu:userData.adminData,
              dimention:'administrator',
            })
            break;

          case 'I':
            setUser({
              ...user,
              menu:userData.engineerData,
              dimention:'ingeniero',
            })
            break;

          case 'C':
            sessionStorage.setItem('menu',JSON.stringify(userData.clientData))
            setUser({
              ...user,
              menu:userData.clientData
            })
            break;

          case 'O':
            setUser({
              ...user,
              menu:userData.operatorData,
              dimention:'operador',
            })
          break
      }
    }
 /*  } */
},[userData])



  return(
        <UserContext.Provider value={[user,setUser]} >
            {props.children}
        </UserContext.Provider>
  )
}
