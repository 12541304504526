import React from 'react';
import EaseInOut from '../components/presentational/EaseInOut';
import LogOutButton from '../components/login/LogOutButton';
import CenteredPageContainer from '../components/pageContainers/CenteredPageContainer';

const LogOutPage = () => {

    return (
            <EaseInOut children={
            <div style={{width:'200px',height:'100vh',display:'flex',margin:'auto',alignItems:'center',}}>
                <LogOutButton/>
            </div>}/>
    );
}

export default LogOutPage;
