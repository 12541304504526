import React from 'react';
//components
import AddUser from '../AddPages/AddUser';
import PageContainer from '../../components/pageContainers/PageContainer'
//Router
import { Route,Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../services/tgd.config';
import HomePage from '../HomePage';
import NotificationsPage from '../NotificationsPage';
import FactoryList from '../../components/lists/FactoryList';
import PlantsList from '../../components/lists/PlantsList';
import ConfigPage from '../ConfigPage';



const AccountManagerContent = ({dimention}) => {

    return (
        <PageContainer>
        <Switch>
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}><AddUser /></Route>
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.notifications}`}>
                <NotificationsPage />
            </Route>
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.factories}`}>
                    <FactoryList/>
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.plants}`}>
                    <PlantsList/>
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>
            <Route path={`/${USER_ROUTES.home}/${dimention}`}>
                <HomePage dimention ={dimention}/>
                <NotificationsPage />
            </Route>
        </Switch>
    </PageContainer>
    );
}

export default AccountManagerContent;