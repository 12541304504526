import React, { useEffect,useContext,useState } from 'react';

//Services
import {getAllPlants} from '../../../../services/axiosTgd'

//material
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Toolbar } from '@material-ui/core';

//context
import { SessionContext } from '../../../../store/sessionContext';
import { getPbiToken } from '../../../../services/pbi.service';
import { useToken } from '../../../../services/session.service';
import { useAllPlants, usePlantsByFactory } from '../../../../services/plant.service';






//Styles of material ui components
const useStyles = makeStyles((theme) => ({
    card:{
        position:"relative",
        padding:'24px',
        margin:'24px 0 0 0'
    },
    grid:{
        width:'auto',
        flexWrap:'wrap',
        display:'flex',
        justifyContent:'flex-start',
        justifySelf:'center',
        alignContent:'flex-start',
        margin:'24px 0 0 ',
    },
    dimentionSelector:{
        display:'flex',
        color:'#fff',
        textTransform:'uppercase',
        height:'130px',
        width:'130px',
        margin:'8px',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'5px',
        cursor:'pointer',
        position:'relative',


    },
    boxSelector:{
        width:'24px',
        height:'24px',
        position:'absolute',
        bottom:'5px',
        right:'5px',
        borderRadius:'100%',
        pointerEvents: 'none',

    }
}));


const PlantsGrid = ({color,selectByFactory,newPlant,handleAvailablePlants}) => {

    const classes = useStyles();
    const {data:plants,isLoading,status,refetch} = usePlantsByFactory(selectByFactory.name.toUpperCase())

    useState(()=> {
        if(newPlant){
            console.log('refetch')
            refetch()
        }
    },[newPlant])

    if(status==='loading')return <CircularProgress/>
    return (
        <Paper className={classes.card} style={{ borderTop:`4px solid ${color}` }}>
                    {/* factories */}
                    {plants && 
                    <>
                        <Toolbar style={{justifyContent:'space-between',padding:'8px'}}>
                            <Typography variant='button' component={'span'}>Plantas</Typography>
                            {/* <Button onClick={allFactories} variant="outlined" style={{right:'0'}} color="primary"> 
                                TODAS
                            </Button> */}
                        </Toolbar>
                        <Box textAlign="center" className={classes.grid}>
                            {plants.map(item =>
                                <Box id={item.name} key={item.name} className={classes.dimentionSelector} style={{backgroundColor:`${color}`}}>
                                    {item.name}
                                    {/* { checked != null &&
                                       
                                        <Box className={classes.boxSelector} id={item.id} style={availableFactories.length === 1 ? {backgroundColor:'green'} : {backgroundColor:'#707070'}}></Box>
                                        //<Box className={classes.boxSelector} id={item.id} style={selectedItemVerification(item.name)}></Box>
                                    } */}
                                </Box>)}
                        </Box>
                    </> 
                    }  
        </Paper>  
    );
}

export default PlantsGrid;
