import React from 'react';
//assets
import LoginBackgroundImg from '../../assets/img/login.png' 
//material
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paperImg: {
      position:'block',
      top:'0',
      left:'0',
      margin:'0',
      width: '100%',
      height: '100vh',
      backgroundImage:`url(${LoginBackgroundImg})`,
      backgroundPosition:'center',
      backgroundBlendMode: 'luminosity', 
      backgroundSize:'cover',
      filter: 'grayscale',
      marginBottom:'-100vh',
      transition: 'background-color 0.5s ease',

  },

pageBack:{
    backgroundColor:'#EFEBE9',
    height:'100vh',
    width:'100%',
    psition:'relative',
    marginBottom:'-100vh',
  

  }

}));

const LoginBackground = (props) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.pageBack}/>
            <div className={classes.paperImg} style={{backgroundColor:'#'+props.bgColor,/* '#56ACDE' '#EFEBE9' */}} />
        </div>
    );
}

export default LoginBackground;
