import React from 'react';
import { useEffect,useState,useContext } from 'react';
import {SessionContext} from '../../store/sessionContext'
import ResourceList from './ResourceList';
//services
import {getAllResources,getOperatorsByFactory,getAllItemsByEmail} from '../../services/axiosTgd'
//material
import {makeStyles} from '@material-ui/core/styles'
import { TextField, Toolbar, Paper, Typography } from '@material-ui/core';
import { useUser } from '../../services/person.service';
import { useToken } from '../../services/session.service';

const useStyles = makeStyles((theme) =>({
    ResourceListContainer:{
        padding:'24px 0 0',
        position:'relative',
        minHeight:'calc(100vh - 25vh)',
        maxHeight:'calc(100vh - 25vh)',
    }
}))




const ResourceListContainer = ({roleFilter,endpoint,onLoad,handleNext,handleState,searchBarTitle,reload}) => {
    const classes = useStyles()
    const [resources,setResources] = useState(null)
    const [filteredResources, setFilteredResources] = useState(null);
    const token = useToken()
    const [search,setSearch] = useState('')
    const {data:user} = useUser()

    //query
    useEffect(()=>{
        if((endpoint != null)){
            if((endpoint !== 'FactoryOperators')){
                
                if(roleFilter != null){
                    getAllResources(endpoint,token)
                    .then(res => {
                        /* console.log(res.data) */
                        setResources(res.data.filter(item => item.role === roleFilter))             
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    
                }else{
                    getAllResources(endpoint,token)
                    .then(res => {
                        /* console.log(res.data) */
                        setResources(res.data)
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                }
            }

            if((endpoint === 'FactoryOperators')){
                getAllItemsByEmail('AccountManagerFactories',user.email,token)
                .then(res=> {
                    getOperatorsByFactory(res.data[0].tagF,token)
                    .then(res=> {
                        setResources(res.data)
                    })
                })
                .catch(err => console.log(err))
                
            } 

            
        }
    },[endpoint,reload])


    useEffect(()=>{//default data
        if(resources != null){
            setFilteredResources(resources)
        }

    },[resources])

    useEffect(() => {//Search logic
        if(resources != null){
            if(search.length > 10){
                let searched = resources.filter(item => item.name.toLowerCase().includes(search.trim()))
                if(searched.length /* > 10 */){
                    let tenElements = searched.slice(0,10)
                    setFilteredResources(tenElements)
                }
                setFilteredResources(searched)
             }/*else{
                tenResults()
            } */
        }
        
    }, [search]);

    /* const tenResults = () => {
        if(resources.length /* > 7 ){
            let tenElements = resources.slice(0,7)
            setFilteredResources(tenElements)
        }
        setFilteredResources(resources)
    } */

    const entity = (data) => {
        handleState(data)
    }
    const handleSearch = (e) =>{
            setSearch(e.target.value.toLowerCase())
    }

    if(filteredResources){
        return (
            <div className={classes.ResourceListContainer}>
                <Paper elevation={1}>
                    <Toolbar style={{display:'flex', justifyContent:'space-between'}} >
                        <Typography variant='button' >
                            {searchBarTitle}
                        </Typography>
                        <TextField 
                            style={{width:'350px'}}
                            value={search}
                            type='search' 
                            onChange={(e) => handleSearch(e)} 
                            placeholder='buscar...' 
                            />
                    </Toolbar>
                </Paper>
                <br/>
                <div style={{ overflowX:'hidden',height:'592px'}}>

                    {filteredResources.map((item,index) =>(
                        
                        <ResourceList
                        key={index}                       
                        state={item}
                        actionEdit={handleNext} 
                        handleState={entity}
                        />
                        ))}
                    
                </div>
                {/* <Paper elevation={1} style={{display:'flex', justifyContent:'center',position:'absolute',bottom:'0',width:'100%'}}>
                    <Toolbar style={{display:'flex', justifyContent:'center'}} >
                        Pagina 1 de 1
                    </Toolbar>
                </Paper> */}
            </div>
        )
    }else{
        return(
            <div className={classes.container} >
                <br/>
                <Paper elevation={1}>
                    <Toolbar style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography variant='button' >
                        {onLoad}
                        </Typography>
                    </Toolbar>
                </Paper>
            </div>
        )
    }
    
}

export default ResourceListContainer;
