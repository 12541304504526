import React,{ useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { Box, Typography, withStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'
import FavoriteIcon from '@material-ui/icons/Favorite';



const StyledRatingHearth = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
  })(Rating);


const satisfied = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};


function IconSatisfaction(props) {
  const { value, ...other } = props;
  return <span {...other}>{satisfied[value].icon}</span>;
}

IconSatisfaction.propTypes = {
    value: PropTypes.number.isRequired,
};




const HearthRating = ({title,handleState}) => {
    const [value,setValue] =useState(1)
    
    useEffect(()=> {
        switch (value) {
            case 1:
                handleState('hearts',value)
                break
            case 2:
                handleState('hearts',value)
                break
            case 3:
                handleState('hearts',value)
                break
            case 4:
                handleState('hearts',value)
                break
            case 4:
                handleState('hearts',value)
                break
        }
        
    },[value])
    
  return (
    <>

        <Box component="fieldset" mb={3} borderColor="transparent">
            <Typography component="legend">{title}</Typography>
            <StyledRatingHearth
            name="customized-color"
            defaultValue={3}
            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={1}
            max={5}
            onChange={(event, newValue) => setValue(newValue)}
            icon={<FavoriteIcon fontSize="inherit" />}
            />
        </Box>
    </>
  );
}

export default HearthRating

