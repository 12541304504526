import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios'
import { URL, USER_ROUTES } from './tgd.config'
import {getTokenVerification, getAuth} from './tgd.axios.service'
import { SessionContext } from '../store/sessionContext';
import { useQuery,useMutation} from 'react-query';
import { useHistory } from 'react-router-dom';
import { usePbiAccesToken } from './pbi.service';

//----------------------------
//AXIOS REPO
//----------------------------




//----------------------------
//react-query HOOKS
//----------------------------


export const useAuth = (data) => {
    return useQuery('token',()=>getAuth(data),{
        retry:0
    })
}

export const useValidateToken = () => {
    const token = useToken()
    const {data,status} = useQuery(['ValidateJwtToken',token],getTokenVerification,{
        retry:0
    })
    
    return [data,status,token]
}



//----------------------------
//CUSTOM HOOKS
//----------------------------

export const useToken = (key) =>{
    const sessionStorageToken = JSON.parse(sessionStorage.getItem('token'))
    if(sessionStorageToken) return sessionStorageToken
    return false
}

export const useLogin = () => {
    const [mutate,data] = useMutation(getAuth)
    const history = useHistory()
    const login = (values) =>{
        mutate(values)
        .then(() => {
            history.push(USER_ROUTES.home)
        })
        .catch(err =>console.log(err))
    }
    return [data,login]
}

export const useLogout = () => {

    const history = useHistory()
    const logout = () =>{
        sessionStorage.clear()
        history.push(USER_ROUTES.login)
    }

    return [logout]
}