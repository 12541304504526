import React, { useEffect } from 'react';
//material
import {Box, CircularProgress } from '@material-ui/core'
import { StyleRounded } from '@material-ui/icons';

const Loading = ({color}) => {


    return (
        <Box style={{width:'100vw',flexDirection:'column',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{ backgroundColor:'#ffffff', width:'80px',flexDirection:'column',height:'80px', borderRadius:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <CircularProgress  disableShrink  />
            </div>
        </Box>
    )
}
export default Loading;
