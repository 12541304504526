import React, { useContext } from 'react';

//Components
import EaseInOut from '../../components/presentational/EaseInOut'
import Loading from '../../components/presentational/Loading'

//material
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

//context

//Router
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../store/userContext';
import { PbiContext } from '../../store/pbiContext';
import LogOutButton from '../../components/login/LogOutButton';


const useStyles = makeStyles((theme) => ({
  plantSelectorContainer: {
    position:'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(35),
      height: '26vh',
      cursor:'pointer',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#fff',
      textTransform:'uppercase',
      '&:hover': {
        boxShadow: '0px 0px 47px -16px rgba(0,0,0,0.75)',
      }
    },
    justifyContent:'center',
    alignContent:'center',
    width:'100vw',
    height:'100vh',
    
    /* backgroundColor:'#EFEBE9', */
  },
  logOut:{
    position:'absolute',
    display:'flex',
    gap:'8px',
    margin:'16px',
	top:'0',
	right:'0',
    width:'218px'
},

}));

  const PlantSelector = ({plants,select}) => {
  //material
  const classes = useStyles();
  //tgd context
//  const [user,setUser] = useContext(UserContext);
 // const [pbi,setPbi] = useContext(PbiContext);
  //router
  const history = useHistory()

  
  //const navigatePlant = (e) => {
  //  const g = pbi.groups.filter(group => group.name === e.target.id)
  //  setPbi({...pbi,userWorkspace:g[0]})
  //  sessionStorage.setItem('workspace',e.target.id)
  //  setUser({
  //    ...user,
  //    multiPlant:!user.multiPlant,
  //    dimention:sessionStorage.getItem('workspace'),
  //    plant:sessionStorage.getItem('workspace'),
  //    //menu:JSON.parse(sessionStorage.getItem('menu'))//Reset menu 
  //  })
  //  history.push('/admin/' + e.target.id)
  //}

 

 
    return (
      <>
        <EaseInOut children={ 
          <div className={classes.plantSelectorContainer}>
            {plants.map((item,index) => (
                <Paper elevation={4} autoFocus={true} color="secondary" variant='outlined' onClick={(e)=> select(e)} id={`${item.factory} ${item.name}`} key={index}> 
                  {item.factory} {item.name} 
                </Paper>
              ))} 
          </div>
        }/>
        <div className={classes.logOut}>
          <LogOutButton title={'salir'}/>
        </div>
      </>
      )

}

export default PlantSelector