import React, { useEffect, useState, useContext } from 'react';
import axios from'axios'
//services
import { getAllResources, getAllResourcesByEmail } from './tgd.axios.service'
import { useMutation, useQuery } from 'react-query';
import {useToken} from './session.service'
import { URL } from './tgd.config';
import { useUser } from './person.service';
import { assignCancelClientToManager } from './axiosTgd';

//axios repo

export const createFactory = ({newFactory,token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/CreateFactory`,
        data:newFactory
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

export const assignCancelClientAccount = ({parameters,token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/AssignCancelClientAccount`,
        data: parameters
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}




//react-query HOOKS
//---------------------------------------------

export const useAllFactoriesWithPlants = () => { //get factories that contain at list one plant
    const token = useToken()
    return useQuery(['AllFactoriesWithPlants',token], getAllResources)
}

export const useAccountManagerFactories = (email) =>{
    const token = useToken()
    return useQuery(['AccountManagerFactories', email, token], getAllResourcesByEmail)
}

export const useAllFactories  = () => {
    const token = useToken()
    return useQuery(['AllFactories',token],getAllResources)
}

export const useFactoriesByUser = () =>{
    const {data:user} = useUser()
    const factories = useAccountManagerFactories(user.email)
    const allFactories = useAllFactories()

    if(user){
        switch (user.type) {
            case 'SA':
                return allFactories
            case 'A':
                return allFactories
            case 'AC':
                return factories
            case 'I': 
                return factories
        }
    }

}



//react-query Mutations
//---------------------------------------------


 const useAssignCancelClientAccount = () =>{
    return useMutation(assignCancelClientAccount)
}

export const useCreateFactory = (config) => {
    const token = useToken()
    return useMutation(createFactory,config)
}

//custom HOOKS
//---------------------------------------------

 export const useAssignCancelFactory = () => {
    const token = useToken()
    const user = useUser()
    const{data:allFactories,refetch}= useAllFactories()
    const [handleClientAccount,useQuery] = useAssignCancelClientAccount({
        onSuccess:refetch
    })

    const assignFactory = ({email,tagF}) => {
        const parameters = {
            email,
            tagF,
            state:'A'
        }
        console.log('entra')
        return handleClientAccount({parameters,token})
    }

    const cancelFactory = ({email,tagF})=>{
        const parameters = {
            email,
            tagF,
            state:'C'
        }
        return handleClientAccount({parameters,token})
    }

    

    return [
        {
        assignFactory,
        cancelFactory
        },
        useQuery
    ]
}

//pbi.service



