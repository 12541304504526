import React, { useState } from 'react'
//components
import LoginForm from '../components/login/loginForm'
//material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { USER_ROUTES } from '../services/tgd.config';
import {Chip} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({

  loginContainer:{
    marginTop:'-100vh',
    width:'100vw',
    height:'100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '1',

},
  container:{
    justifyContent:'center',

  },
  tagLine:{
    fontSize:'12px',
    marginTop:'100px',
    color:theme.palette.secondary.main,
    textAlign:'center'
  },

}));


const LoginPage = (props) => {
    
  const classes = useStyles();
    
    return (
            <>
                {/* <Redirect from='/' to={`/${USER_ROUTES.login}`}/> */}
				<div className={classes.loginContainer}>
					<Grid container className={classes.container} >
						<Grid justify="center" container style={{maxWidth:'350px',}} item lg={3} md={4} sm={8} xs={10}>
								<LoginForm >
									<Switch>
										<Route path={`/${USER_ROUTES.demoRequest}`}>
											<Chip
											onDelete={true}
											deleteIcon={<ArrowBackIcon/>}
											size="small"
											label={'Ingresar'}
											clickable
											color="secondary"
											component={NavLink}
											to={`/${USER_ROUTES.login}`}
											/>
										</Route>
										<Route path={`/`}>
											<Chip
											onDelete={true}
											deleteIcon={<ArrowForwardIcon/>}
											size="small"
											label={'Solicitar Demo'}
											clickable
											color="secondary"
											component={NavLink}
											to={`/${USER_ROUTES.demoRequest}`}
											/>
										</Route>
									</Switch>
								</LoginForm>
						</Grid>
					</Grid>
				</div>
			</>
        )
}

export default LoginPage
          