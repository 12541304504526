import React,{useState,useEffect,useContext} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

//context
import { SessionContext } from '../../store/sessionContext';
//components
import ResourceGrid from './ResourceGrid';
//router
import { useLocation } from 'react-router-dom';

//services
import { getAllResources, getAllItemsByEmail } from '../../services/axiosTgd'
import { useAllFactoriesWithPlants, useAccountManagerFactories } from '../../services/factory.service';
import { usePlants,  } from '../../services/person.service';
import { useEngineerPlants, useOperatorPlants } from '../../services/plant.service'
import { useToken } from '../../services/session.service';



const ResourceGridContainer = ({color,title,action,endpoint,email,onLoad,filterByEmail,refresh}) => {

    const [allItems, setAllItems] = useState();
    const token = useToken()
    const location = useLocation()
    const [loading,setLoading] = useState(false)
    /* const factories = useAllFactoriesWithPlants()
    const plants = usePlants()
    const accountManagerFactories = useAccountManagerFactories(email)
    const engineerPlants = useEngineerPlants(email)
    const operatorPlants = useOperatorPlants(email) */

    const childrenComponent = <CircularProgress color="secondary" />
    

    useEffect(() => {

        if(endpoint === 'AllFactoriesWithPlants'){
            setLoading(true)
            getAllResources(endpoint,token,)
            .then(res => {
                let Resources = res.data.filter((item) => filterByEmail.includes(item.referrer))
                setAllItems(Resources)
                setLoading(false)
            })
            .catch(res => console.log(res))
        }

        if(endpoint === 'Plants'){
            setLoading(true)
            getAllResources(endpoint,token,)
            .then(res => {
                if(location.pathname.split('-').reverse()[0] === 'ingeniero'){
                    let resources = res.data.filter(item => filterByEmail.includes(item.referrer))
                    setLoading(false)
                    setAllItems(resources)
                }else{setAllItems(res.data)}
            })
            .catch(res => console.log(res))
        }

        if((endpoint === 'AccountManagerFactories' ) && (email !=null)){
            setLoading(true)
            getAllItemsByEmail(endpoint, email, token)
            .then(res => {
                setAllItems(res.data)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }

        if((endpoint === 'EngineerPlants' ) && (email !=null)){
            setLoading(true)
            getAllItemsByEmail(endpoint, email, token)
            .then(res => {
                setAllItems(res.data)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }
        if((endpoint === 'OperatorPlants' ) && (email !=null)){
            setLoading(true)
            getAllItemsByEmail(endpoint, email, token)
            .then(res => {
                setAllItems(res.data)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }

    }, [endpoint, email, refresh]);


   
    return (
        <div>
            
            <ResourceGrid 
                title={title}
                color={color}
                allResources={allItems}
                action={action}
                filter={'desarrollo@tgdsa.com'}
                onLoad={onLoad}
                loading={loading}
                children={childrenComponent}
            />
            
        </div>
    );
}

export default ResourceGridContainer;
