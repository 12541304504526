import React, {  useEffect, useState } from 'react';

import { Button, Icon,makeStyles, IconButton, useTheme, Stepper, Step, StepLabel} from '@material-ui/core'

import { useAllFactories, useFactoriesByUser } from '../../services/factory.service';
import { useUser } from '../../services/person.service';
import TgdModal from '../TgdModal';
import TgdList from './TgdList';
import TgdListToolbar from './TgdListToolbar';
import ListContainer from '../pageContainers/ListContainer';
import AddFactoryFormRefactor from '../forms/AddFactoryFormRefactor';
import AddPlantFormRefactor from '../forms/AddPlantFormRefactor';
import DuoContainer from '../pageContainers/DuoContainer';
import PlantsGrid from '../Admin/AddClient/Grids/PlantsGrid';
 
const useStyles = makeStyles((theme) => ({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    },
    relative:{
        position:'relative'
    }
  }));

  const TgdStepper = ({activeStep,steps,children}) => {
    return(<>    
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => 
                    <Step key={label}>
                        <StepLabel >{label}</StepLabel>
                    </Step>
            )}
        </Stepper>
        {children}
    </>)
  }

      

const FactoryList = () => {
    const classes = useStyles()
    const theme = useTheme()
    const [open,setOpen] = useState(false)
    const {data:user} = useUser()
    const [filteredResources, setFilteredResources] = useState();
    const {data:allFactories,refetch} = useAllFactories()
    const {data:factories,isFetching} = useFactoriesByUser()
    const [search,setSearch] = useState('')
    const [activeStep, setActiveStep] = useState(0);
    const [newFactory,setNewFactory] = useState()



    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
        
    const handleReset = () => {
        setActiveStep(0);
    };


    useEffect(() => {//Search logic
        if(factories){
            let searched = factories.filter(item => item.name.toLowerCase().includes(search.trim()))
            setFilteredResources(searched)
        }
    }, [search]);

    useEffect(()=> {
        if(factories){
            setFilteredResources(factories)
        }
    },[factories])

    
    const handleSearch = (e) =>{
        setSearch(e.target.value.toLowerCase())
    }

    const handleNewFactory = (item) =>{
        let config ={factoryId:item.factoryId,name:item.tagF}
        setNewFactory(config) 
        handleNext()
    }

    const handleModal = () => {
        setOpen(!open)
    }

    const customAction = () => {
        handleModal()
    }
    const customButtons = (item) => {
        if(user && (user.type !== 'I' ) && (user.type !== 'O')){
            return(
                <IconButton style={{margin:'0 0'}} variant='outlined' edge="end" aria-label="Asignar Plantas" onClick={()=>customAction(item)} color={'primary'}>
                    <Icon>business</Icon>
                </IconButton > 
            )
        }
    }

    return (
        <ListContainer>
            <TgdListToolbar 
                searchBar={true}
                title={'Fábricas'}
                searchValue={search}
                handleSearch={handleSearch}
                actions={(user.type !== 'AC') && <Button color={'secondary'} onClick={handleModal} variant='contained'><Icon>add</Icon>Fabrica</Button>}
            />
            <TgdList
                title={'Fábricas'}
                icon={'business'}
                status={isFetching}
                items={filteredResources}
                toggleDisabled={true}
            />
            <TgdModal fullWidth={true} title={'Crea una Fábrica'} footerActions={<Button variant='outlined' onClick={()=>handleReset()}>cargar otra</Button>} open={open} handleClose={handleModal}>
                    <TgdStepper 
                    activeStep={activeStep}
                    steps={['Crea una Fábrica', 'Crea un aPlanta']}>

                        {activeStep === 0 && 
                        <><AddFactoryFormRefactor
                        color={theme.palette.secondary.main}
                        handleFactory={handleNewFactory}/>
                        </>}

                        {activeStep === 1 && 
                        <><DuoContainer>
                            <PlantsGrid 
                            color={theme.palette.primary.main}
                            selectByFactory={newFactory}
                            />
                            <AddPlantFormRefactor
                            color={theme.palette.primary.main}
                            selectByFactory={newFactory}/>
                        </DuoContainer>
                        </>}
                    </TgdStepper>
            </TgdModal>
        </ListContainer>
    );
}

export default FactoryList;
