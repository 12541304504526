import React, { useEffect, useState } from 'react';
import {Paper,Box, Toolbar, Typography, Grid, Button, Icon, AppBar,makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Divider, CircularProgress, TextField} from '@material-ui/core'
import PageContainer from '../pageContainers/PageContainer';
import { Alert, ToggleButton } from '@material-ui/lab';
import { Accordion } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import UpdatePersonForm from '../forms/UpdatePersonForm';
import { Height } from '@material-ui/icons';
import TgdListToolbar from './TgdListToolbar';
import TgdList from './TgdList';
 
const useStyles = makeStyles((theme) => ({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    },
    relative:{
        position:'relative',
        height:'calc(100vh - 19vh)',
        overflowX:'hidden',
    },
    MuiAccordionRoot:{
        boxShadow:'none',
        margin:'0',
        padding:0,
    }
  }));




const EntityList = ({icon,status,items,customButtons,title,children}) => {
    const classes = useStyles()
    const [filteredResources, setFilteredResources] = useState();
    const [search,setSearch] = useState('')

    useEffect(() => {//Search logic
        if(!!items){
            let searched = items.filter(item => item.name.toLowerCase().includes(search.trim()))
            setFilteredResources(searched)
        }
    }, [search]);

    useEffect(()=> {
        if(items){
            setFilteredResources(items)
        }
    },[items])

    const handleSearch = (e) =>{
        setSearch(e.target.value.toLowerCase())
    }
    
    return (
        <Paper className={classes.relative}>
            {children}
            {/* <TgdListToolbar 
                title={title}
                searchValue={search}
                handleSearch={handleSearch} >
                <Button color={'secondary'} variant='contained'><Icon>add</Icon>Fabrica</Button>
            </TgdListToolbar> */}
            <Toolbar className={classes.FactoryListTolbar}>
                <Typography variant='h6' > {title}</Typography>
                <TextField 
                    style={{width:'300px',margin:'0 16px'}}
                    value={search}
                    type='search' 
                    onChange={(e) => handleSearch(e)} 
                    placeholder='buscar...' 
                    />
            </Toolbar> 
                <TgdList
                status={status}
                icon={icon}
                items={filteredResources}
                customButtons={customButtons}
                ></TgdList>
        </Paper>
    );
}

export default EntityList;
