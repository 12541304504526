import { Paper } from '@material-ui/core'
import React from 'react'
import UpdatePersonForm from '../components/forms/UpdatePersonForm'
import TgdListToolbar from '../components/lists/TgdListToolbar'
import PageContainer from '../components/pageContainers/PageContainer'
import { useUser } from '../services/person.service'

const ConfigPage = () => {
    const {data:user} = useUser()

    return(
        <Paper>
            <TgdListToolbar 
                title={'Configuraciones'}
            />
            <PageContainer>
                <UpdatePersonForm person={user}/>
            </PageContainer>
        </Paper>
    )
}

export default ConfigPage