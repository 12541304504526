import React,{useContext, useEffect} from 'react'
//components
import Home from '../../components/client/Home'

import Reports from '../../components/pbi/Reports'
import PlantSelector from '../../layouts/clients/PlantSelector'
import PageContainer from '../../components/pageContainers/PageContainer';
import NotificationsPage from '../NotificationsPage' 


//router
import { Route,Switch, Redirect, useHistory } from 'react-router-dom'
//services
import { useUser, useDimention,usePlants } from '../../services/person.service'
import { usePbiReportsInGroup,usePbiTilesInDashboard } from '../../services/pbi.service'
import {  USER_ROUTES,  } from '../../services/tgd.config'
import Loading from '../../components/presentational/Loading'
import DashLayout from '../../layouts/DashLayout'
import { SessionContext } from '../../store/sessionContext'

import LogOutPage from '../LogOutPage.js'
import ConfigPage from '../ConfigPage'
import AddDemoUserPage from '../AddPages/User/AddDemoUserPage'





const ClientContent = ({aadToken}) => {

  const [auth,setAuth] = useContext(SessionContext)
  const {data:plants, isLoading} = usePlants()
  const {data:user} = useUser()
  const reports = usePbiReportsInGroup()
  const {} = usePbiTilesInDashboard()
  const history = useHistory()
  const dimention = useDimention()


/*   useEffect(() => {
    if(groupId && aadToken){

      getPbiInfoByGroupID('reports',groupId,aadToken).then(res=>console.log(res.data.value))
    }
  },[groupId]) */

  useEffect(()=>{
    if(history.location){
      let plantSelector=`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`
      let workspace = `/${USER_ROUTES.home}/workspace:`

      let path = history.location.pathname
      if(path === plantSelector){
        setAuth({...auth,multyPlant:true})
      }

    }
  },[history.location])

  useEffect(() => {
    if(plants && plants.length > 1){
      setAuth({...auth,multyPlant:true})
    }
    if(plants && (plants.length === 1)){
      let workspace = `${plants[0].factory} ${plants[0].name}`
      setAuth({...auth,multyPlant:false,workspace})
    }
  }, [plants]);

  const selectWorkspace = (e) => {
    setAuth({...auth,multyPlant:false,workspace:e.target.id})
  }


  if(plants && auth.multyPlant){
    return(
      <>  
        <Redirect to={`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`} />
        <Switch>
          <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`}>
            <PlantSelector plants={plants} select={selectWorkspace}/>
          </Route>
          <Route path={`/`}>
            <LogOutPage />
          </Route>
        </Switch>
      </>
    )
  }

  if((plants && !auth.multyPlant && reports))
  
  return (
    <>
    	<Redirect to={`/${USER_ROUTES.home}/${auth.workspace}`}/>
      		<DashLayout>
				{user && (user.email === 'd.lavallen@tgdsa.com') &&
					<PageContainer>
						<Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
							<AddDemoUserPage />
						</Route>
					</PageContainer>
				}
        	  	<Switch>
					{/* <Route children={<CalendarPage />} exact path={`/admin/${user.dimention}/cronograma de planta`}/> */}
					{/* <Route exact path={`/${USER_ROUTES.home}/${auth.workspace}`}> <> <Reports />  </></Route> */}
        	    <Route  path={`/${USER_ROUTES.home}/${auth.workspace}/${USER_ROUTES.notifications}`}> <NotificationsPage /></Route>
              <Route path={`/${USER_ROUTES.home}/${auth.workspace}/${USER_ROUTES.config}`}>
                <PageContainer>
                    <ConfigPage/>
                </PageContainer>
              </Route>
        	    <Route  path={`/${USER_ROUTES.home}/${auth.workspace}`}>
                	<Switch>
						<Route exact path={`/${USER_ROUTES.home}/${auth.workspace}`}><Home/></Route>
						<Route path={`/${USER_ROUTES.home}/${auth.workspace}`}><Reports url={`/${USER_ROUTES.home}/${auth.workspace}`} /></Route>
                	</Switch>
        	    	</Route>
        	  </Switch>
      		</DashLayout>
      </>
    )
    
    return( <Loading/>)
      
}

export default ClientContent;
