import React,{ useState, useEffect } from 'react';

//components
import ResourceGridContainer from './grids/ResourceGridContainer';

//services
import { usePbiGroups, useAadToken,useAddUserInPbiGroup, useDeleteUserInPbiGroup } from '../services/pbi.service'
import {useAssignCancelFactory}from '../services/factory.service'






const FactoryAssigment = ({color,title,email,handleFactory,onLoad,action,filterByEmail,handleRefresh,refresh}) => {

    const [loading, setLoading] = useState({
        inCourse1:false,
        inCourse2:false,
        success:true
    });
    const [itemSelected,setItemSelected] = useState({
        email:null,
        tagF:''
    })

    const [pbiGroupName,setPbiGroupName] = useState(null)
    const pbiToken = useAadToken()
    const {data:pbiGroups} = usePbiGroups(pbiToken)
    const [addUserInPbiGroup] = useAddUserInPbiGroup()
    const [deleteUserInPbiGroup] = useDeleteUserInPbiGroup()
    const [factory] = useAssignCancelFactory()


    const pbiAccesUserConfig = {
        emailAddress: email,
        groupUserAccessRight: "Contributor"
    }

    const handleGroups = (rule) => {
        if(rule){

            let pbiPlants = pbiGroups.data.value.filter(g => g.name.toLowerCase().includes(pbiGroupName.toLowerCase()))
            if(action === 'asign'){
                pbiPlants.forEach(item => {
                    const queryConfig = {
                        groupId:item.id,
                        parameters:pbiAccesUserConfig,
                        token:pbiToken
                    }
                    addUserInPbiGroup(queryConfig)
                    .then(res =>{
                        console.log(`se agrego correctamente ${pbiAccesUserConfig.emailAddress} como contribuidor en el grupo ${item.name}`)
                    })
                    .catch(err => {
                        console.log(err)
                        alert(`no se ha podido asignar ${pbiAccesUserConfig.emailAddress} como Contribuidor en el grupo ${item.name}`)
                    })
                })
            }
            if(rule === 'cancel'){
                pbiPlants.forEach(item => {
                    
                    const queryConfig = {
                        groupId:item.id,
                        user:pbiAccesUserConfig.emailAddress,
                        token:pbiToken
                    }
                    deleteUserInPbiGroup(queryConfig)
                    .then(res => {
                        console.log(`se eliminó correctamente ${pbiAccesUserConfig.emailAddress} del grupo ${item.name}`)
                    })
                    .catch(err => console.log(err))
                })
            }
        }
    }
    


    //set Data to query
    const setItem = (item) =>{
        setItemSelected({
            tagF:item.tagF,
            email:email,
            state:action.charAt(0).toUpperCase()
        })
        setPbiGroupName(item.name)
    }

    useEffect(()=>{
        setItemSelected({
            ...itemSelected,
            email:email
        })
        

    },[email])
   

    //make action query  
    useEffect(()=>{
        if(action){

            if((itemSelected.email != null) && (itemSelected.tagF) && (action === 'asign') && (pbiGroupName) ){
                handleGroups(action)
                factory.assignFactory(itemSelected)
                .then(res => {
                    handleRefresh()
                    handleFactory(itemSelected)
                    setLoading({success:!loading.success})
                })
                .catch(err => console.log(err))
            }

            if((itemSelected.email != null) && (itemSelected.tagF) && (action === 'cancel')){
                handleGroups(action)
                factory.cancelFactory(itemSelected)
                .then(res => {
                    handleRefresh()
                    handleFactory(itemSelected)
                    setLoading({success:!loading.success})
                })
                .catch(err => console.log(err))
            }
            
        }
    },[itemSelected])


  if(action){
    if((action === 'asign') ){
        return (
                <ResourceGridContainer //display all Factory grids (filtered by have plants)
                    action={setItem}
                    color={color}
                    title={title}
                    endpoint={'AllFactoriesWithPlants'}
                    onLoad={onLoad}
                    filterByEmail={filterByEmail}
                    refresh={refresh}
                    loading={loading.inCourse1}
                />
        );
    }else if((action === 'cancel')){
        return(
                <ResourceGridContainer //display factories of an account manager
                    action={setItem}
                    color={color}
                    title={title}
                    email={itemSelected.email}
                    endpoint={'AccountManagerFactories'}
                    onLoad={onLoad}
                    refresh={refresh}
                    loading={loading.inCourse2}
                />
            )
        }
  }
    

}

export default FactoryAssigment;
