import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CenteredPageContainer: {
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'24px',

    },
}));

const CenteredPageContainer = ({children}) => {
    const classes = useStyles()

    return (
        <div className={classes.CenteredPageContainer}>
            {children}
        </div>
    );
}

export default CenteredPageContainer;
