import React,{useState,useEffect} from 'react';
//material
import Typography from '@material-ui/core/Typography';
import {  Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Toolbar } from '@material-ui/core';
import GridItem from '../GridItem';


//Styles of material ui components
const useStyles = makeStyles((theme) => ({
    card:{
        position:"relative",
        padding:'24px',
        margin:'24px 0 0 0'
    },
    grid:{
        width:'auto',
        flexWrap:'wrap',
        display:'flex',
        justifyContent:'flex-start',
        justifySelf:'center',
        alignContent:'flex-start',
        margin:'24px 0 0 ',
    },
    dimentionSelector:{
        display:'flex',
        color:'#fff',
        textTransform:'uppercase',
        minHeight:'104px',
        maxHeight:'128px',
        minWidth:'104px',
        maxWidth:'128px',
        margin:'8px',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'5px',
        cursor:'pointer',
        position:'relative',


    },
    boxSelector:{
        width:'24px',
        height:'24px',
        position:'absolute',
        bottom:'5px',
        right:'5px',
        borderRadius:'100%',
        pointerEvents: 'none',

    }
}));

const ResourceGrid = ({filter,color,allResources,title,action,onLoad,children,disabled}) => {

    const classes = useStyles()
    const [resources,setResources] = useState()

    useEffect(()=>{
        if((allResources!=null)){
            setResources(allResources)
        }
    },[allResources])

    const select = item => {
        if(!disabled){
            action(item)
        }
    }
 
    if((resources) && (resources.length > 0)){
        return (
            <div>
                <Paper className={classes.card} style={{ borderTop:`4px solid ${color}` }}>
                    
                        <Toolbar style={{justifyContent:'space-between',padding:'8px'}}>
                        <Typography variant='button' component={'span'}>{title}</Typography>
                            {/* <Button  variant="outlined" style={{right:'0'}} color="primary">
                                TODAS
                            </Button> */}
                        </Toolbar>
                        <Box textAlign="center" className={classes.grid}>
                            {resources.map((item,index) =>
                                <GridItem
                                    action={()=>select(item)}
                                    key={index}
                                    disabled={disabled}
                                    color={color}
                                    item={item}
                                />)}
                        </Box>   
                </Paper>
            </div>
        );
    }else{
        return ( 
            <div>
                <Paper className={classes.card} style={{ borderTop:`4px solid ${color}` }}>
                    {onLoad}
                </Paper>
            </div>
        )
    }

}

export default ResourceGrid;
