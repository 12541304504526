import {createMuiTheme} from '@material-ui/core/styles'


const theme = createMuiTheme({
    palette:{
        primary:{
            main: '#DA291C',//Rojo
            light:'#fff',
            grey:'#f3f3f3'
        },
        secondary:{
            main: '#002F6C',//azul
            gold: '#D98918',
            white: '#fff'
        },
        success:{
            main:'#4caf50'

        },
        light:{
            main:'#ffffff'
        },
        action:{
            selected: '#ED8B00',
            hover: 'rgba(255, 255, 255, 0.6)',
            disabledBackground:'rgba(255, 255, 255, 0.6)',
        },
        background:{
            customBody: '#f3f3f3',
        }
    }

})
export default theme