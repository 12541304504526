import React,{useState,useEffect} from 'react'
//components
import MenuPlantSelector from '../../components/MenuPlantSelector'

//assets
import sidebarImg from '../../assets/img/sidebar.png'
import Logo from '../../assets/img/TGD-blanco.png'

//Material
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Icon from '@material-ui/core/Icon'
import {Typography,Hidden, IconButton, Button} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Toolbar from '@material-ui/core/Toolbar'
//Router
import { Link, NavLink,  } from 'react-router-dom';
import LogOutButton from '../../components/login/LogOutButton';
import { useUser, usePlants,  useDimention, useWorkspace } from '../../services/person.service'
import { useMenu, USER_ROUTES, setNewMenu  } from '../../services/tgd.config'
import { usePbiReportsInGroup } from '../../services/pbi.service'

import NotificationsNavBar from '../../components/notifications/NotificationsNavBar'
import TgdModal from '../../components/TgdModal'
import SupportComponent from '../../components/forms/SupportComponent'



const drawerWidth = 250;
const useStyles = makeStyles(theme => ({

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex:'0',
        
        
    },
    drawerPaper:{
        width: drawerWidth,
        color:'#f3f3f3',
        border:'none',
        backgroundColor:'#002F6C',
        backgroundImage:'url('+ sidebarImg +')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow:'hidden',


    },
    iconDrawer:{
        color:'#f3f3f3',
        pointerEvents: 'none',
        minWidth: '32px'

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        
    },
    activeLink: {
        backgroundColor: '#ED8B00'
    },
    spacing:{
        margin: '0 24px'
    },
    logOut:{
        position:'absolute',
        display:'flex',
        gap:'8px',
        margin:'16px',
        bottom:'16px',
        width:'218px'
    },
    listItem:{
        padding:'0 16px',
        margin:'8px 0 0'
    },
    menuButton:{
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        width:'100%',
    },
    colorSecondary:{
        borderColor: '#fff',
        color: 'white',
        },
    pointerEvents:{
        pointerEvents: 'none'
    },
    flexButton:{
        width:'100%',
        backgroundColor:'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        padding:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
            backgroundColor:'rgba(255, 255, 255, 0.6)',
            cursor:'pointer'
        }
    }
   }))

const Sidebar = (props) => {

    const [menu,setMenu] = useState(useMenu())
    const classes = useStyles();
    const {data:user} = useUser()
    const {data:plants} = usePlants()
    const dimention = useDimention()
    const workspace = useWorkspace()
    const reports = usePbiReportsInGroup()
    const [dropState,setDropstate] = useState(null)
    

    

    useEffect(() => {
        if((reports) && (reports.length > 0)){
            setMenu(setNewMenu(reports,menu))
        }
    }, [reports]);
   

    const userDimention = () =>{
        if(user){
            if(user.type === 'C') return workspace
            else return dimention
        }
    }

    useEffect(() =>{
        if(menu){
            menu.forEach(element => {
                setDropstate(dropState => ({...dropState,[element.id]: false}))
            })
        }
    },[menu])


    //drop menu
    const drop = (e) =>{   
        let i = e.target.id
        let state = !dropState[i] 
        setDropstate({...dropState,[i]:state})
        
    }

    return (  
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor={props.anchor}
            variant={props.variant}
            open={props.open}
            onClose={props.onClose}
        >

            {<div className={classes.toolbar}>
                <Hidden mdUp>
                    <ListItem>
                        <AccountCircle   />
                        {user && <Typography variant="button" component={'span'} noWrap className={classes.spacing} >{user.name} </Typography>}
                    </ListItem>
                </Hidden>
                <Hidden mdUp>
                        <NotificationsNavBar color={'#fff'}/>
                    </Hidden>
                <Hidden smDown>
                    <Toolbar>
                        <img src={Logo} style={{height:'40px',left:'24px'}}alt=""/>
                        {((user) && (user.type === 'C') && (plants) && (plants.length > 1)) ?  <MenuPlantSelector icon={classes.iconDrawer} classes={classes.colorSecondary} color="secondary" />  :null}
                    </Toolbar>
                </Hidden>
            </div>}

            

            <Divider />

            {menu && dropState
            ?
            <div style={{maxHeight:'calc(100vh - 150px)',overflowX:'hidden'}}>
                <div style={{width:'100%',height:'8px'}}></div>
                {menu.map((item, index) => ( 
                    <div key={index} id={index} className={classes.listItem} >
                        <div style={{backgroundColor:'rgba(255, 255, 255, 0.15)',borderRadius:'5px'}}>
                            {(item.actionMenu) && (item.actionMenu.length > 0)  && 
                                <>
                                    <ListItem onClick={(e)=>drop(e)} id={item.id} className={classes.menuButton}  to={`/${USER_ROUTES.home}/${userDimention()}/${item.route}`} button key={index}>
                                        <ListItemIcon className={classes.iconDrawer}><Icon className={item.icon} key={index}>{item.icon}</Icon></ListItemIcon>
                                        <ListItemText className={classes.pointerEvents}><Typography component={'span'} style={{fontSize:'12px'}} variant="button">{item.name}</Typography></ListItemText>
                                        <ListItemIcon className={classes.pointerEvents} style={{justifyContent:'flex-end'}} className={classes.iconDrawer}>
                                            {!dropState[item.id] &&
                                            <KeyboardArrowDownIcon className={classes.pointerEvents}/>
                                            }
                                            {dropState[item.id] &&
                                            <KeyboardArrowUpIcon className={classes.pointerEvents} />
                                            }
                                        </ListItemIcon>
                                    </ListItem>
                                    {dropState[item.id] && //submenuItems
                                        <>
                                            {item.actionMenu.map((subItem,index) =>(
                                                    <div key={index} style={{padding:'8px 8px 0 8px'}}>
                                                        <ListItem component={NavLink} activeClassName='Mui-selected' className={classes.menuButton}  to={`/${USER_ROUTES.home}/${userDimention()}/${subItem.route}` } button >
                                                            <ListItemIcon className={classes.iconDrawer}><Icon className={subItem.icon} key={index}>{subItem.icon}</Icon></ListItemIcon>
                                                            <ListItemText  ><Typography style={{fontSize:'12px'}}component={'span'}  variant="button">{subItem.name}</Typography></ListItemText>
                                                        </ListItem> 
                                                    </div>
                                            ))}
                                            <div style={{width:'100%',height:'8px'}}></div>
                                        </>
                                    }
                                </>
                            }
                            {!item.actionMenu &&
                                <ListItem component={NavLink} activeClassName='Mui-selected' className={classes.menuButton} exact to={`/${USER_ROUTES.home}/${userDimention()}/${item.route}` } button key={index}>
                                    <ListItemIcon className={classes.iconDrawer}><Icon className={item.icon} key={index}>{item.icon}</Icon></ListItemIcon>
                                    <ListItemText  ><Typography component={'span'}  style={{fontSize:'12px'}} variant="button">{item.name}</Typography></ListItemText>
                                </ListItem>
                            }
                        </div>
                    </div> 
                ))}      
            </div>
            :null}
            
            <div className={classes.logOut}>
                <LogOutButton title={'salir'}/>
                {/* <div className={classes.flexButton} >
                    <Icon>person</Icon>
                </div> */}
                <Button className={classes.flexButton} component={Link} to={`/${USER_ROUTES.home}/${(user.type ==='C') ? workspace : dimention}/${USER_ROUTES.config}`}>
                    <Icon style={{color:'#fff'}}>settings</Icon>
                </Button>
                <SupportComponent/>
            </div>


        </Drawer>
    )
}

export default Sidebar