import React from 'react';
//components
import AddUser from '../AddPages/AddUser';
import PageContainer from '../../components/pageContainers/PageContainer'
//Router
import { Route,Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../services/tgd.config';
import HomePage from '../HomePage';
import ConfigPage from '../ConfigPage';

const EngineerContent = ({dimention}) => {
    return (
        <>
        <PageContainer>
            <Switch>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}> 
                    <PageContainer><AddUser/></PageContainer>
                </Route>  
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}`}><HomePage dimention={dimention}/></Route> 
            </Switch>
        </PageContainer>
        </>
    );
}

export default EngineerContent;
