import React,{useState,useContext, useEffect} from 'react';
//context
import {SessionContext} from '../../store/sessionContext'

//Services
import { createPerson, assignPlant, updatePlant, userPlants } from '../../services/axiosTgd'
import { useEmailVerificationExist, useAllPersons, useUser, usePerson, useWorkspace, usePlants, useDimention, useSubordinates, useCreateperson} from '../../services/person.service'

//material
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Paper, Button, InputAdornment, Checkbox, FormControlLabel, FormGroup, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useToken } from '../../services/session.service';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Route, useLocation } from 'react-router-dom';
import { USER_ROUTES } from '../../services/tgd.config';
import { useFactoriesByUser } from '../../services/factory.service';




const useStyles = makeStyles((theme) => ({
    componentContainer:{
        position:"relative",
        padding:'24px',
        margin:'24px 0 0 0',
        minHeight:'400px',
        height:'min-content'
    },
    
    formContainer:{
        display:'grid',
        justifyContent:'start', 
        gap:'16px',
        gridTemplateColumns:'100%',
        alignItems:'starts',
        margin:'24px 0 0 0',

    },
    form:{
        width:'100%',
    },
    fabAdd: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));


const AddPersonsForm = ({color,handleEmail,handleNext,title,endpoint,userType}) => {
    const classes = useStyles()
    const [load,setLoad] = useState({
        loading:null,
        success:false,
        error:''
    })

    const [newPerson,setNewPerson] = useState({
        name: '',
        telephone: '',
        address: '',
        email: 'email.com',
        file: 0,
        referrer: null,
        password: '',
        tagF:'PERSONALIZADO',
        tagFP:'',
    })
    const[ name,setName] = useState({
        firstName:'',
        lastName:'',
    })

    const token = useToken()
    const {data:loggedInPerson} = usePerson()
    const {data:user} = useUser()
    const {data:plants} = usePlants()
    const {data:factoriesByUser} = useFactoriesByUser()
    const {data:subordinates,refetch:refetchSubordinates}= useSubordinates()
    const {refetch:refetchAllPerson} = useAllPersons()
    const [createPerson,{status}] = useCreateperson()

    useEmailVerificationExist(newPerson.email)
    
    
    //constructin complete name
    const setCompleteName = () => {
        setNewPerson({...newPerson,name:`${name.firstName} ${name.lastName}`}) 
    }
  
    //Catch form values
    const handleChange = (e) => {
        setNewPerson({...newPerson,[e.target.name]:e.target.value})
        if(e.target.name === 'email'){
        }
    };

    //manage complete name
    const handleName = (e) => {
        setName({...name,[e.target.name]:e.target.value})
    }
    const handleReferrer = () => {
        setNewPerson({...newPerson,referrer:loggedInPerson[0].id})
    }
    
   

    useEffect(()=>{
        // custom form rule for have < 6 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isPasswordLength')) {
            ValidatorForm.addValidationRule('isPasswordLength', (value) => {
                if (value.length < 6 ) {
                    return false;
                }
                return true;
            });
        }
        // custom form rule for have < 3 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isName')) {
            ValidatorForm.addValidationRule('isName', (value) => {
                if (value < 3 ) {
                    return false;
                }
                return true;
            });
        }
        // custom form rule for have < 10 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isPhone')) {
            ValidatorForm.addValidationRule('isPhone', (value) => {
                if (value < 10 ) {
                    return false;
                }
                return true;
            });
        }
        // custom form rule for have < 10 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('emailExist')) {
            ValidatorForm.addValidationRule('emailExist', (value) => {
                if (parseInt(sessionStorage.getItem('emailVerification')) > 0 ) {
                    return false;
                }
                return true;
            });
        }

    },[newPerson])


    //Create Client
    const sendPerson = () => {
        createPerson({endpoint, parameters:newPerson, token})
        .then(res=>{
            refetchSubordinates()
            refetchAllPerson()
            handleEmail(newPerson.email)
            setLoad({...load,success:true,error:null}) 
            
            if((user.type === 'I')){

                let dataAssignment = {
                    tagFP:plants[0].tagFP,
                    email:newPerson.email,
                    role:'O',
                    state:'A',
                    actualUserEmail:user.email
                }
    
                //Asign plants automaticly
                userPlants(token,dataAssignment.email)
                .then(res => {
                    console.log(res.data)
                    let update = res.data.filter(item => item.tagFP === dataAssignment.tagFP)
    
                    if(update.length === 0){
                        assignPlant(dataAssignment,token)
                        .then(res => {
    
                        })
                        .catch(err => console.log(err))
                    }else{
                    /* handleGroups(action) */
                    updatePlant(dataAssignment,token)
                    .then(res => {
                        
                    })
                    .catch(err => console.log(err))
                    }
                })
                .catch(err => console.log(err))
                
            }
        })
        .catch(err => {
            setLoad({...load,success:false,error:err})
            alert('!Ha Ocurrido un error'+ err)
        })
    
    }

    useEffect(()=>{
        if((load.success)){
            handleNext()
        }
    },[load.success])

    //veryfy

    //Reset Form
    useEffect(() =>{
        if(load.success){
            setNewPerson({
                name: '',
                telephone: '',
                address: '',
                email: '',
                file: 0,
                referrer: user.userId,
                password: '',
                tagF:'PERSONALIZADO',
                tagFP:'',
            })
        }
    },[load.success])

    return (
        <Paper className={classes.componentContainer} style={{borderTop:`4px solid ${color}`}}>
            <Toolbar style={{justifyContent:'space-between',padding:'8px'}}>
                <Typography component={'span'} variant='button' >{title}</Typography>
            </Toolbar>
            <div >
                <ValidatorForm className={classes.formContainer}
                    onSubmit={sendPerson}
                >
                    <TextValidator
                        className={classes.form}
                        label="*Nombre"
                        onChange={handleName}
                        onFocus={handleReferrer}
                        name="firstName"
                        value={name.firstName}
                        validators={['required', 'isName']}
                        errorMessages={['Este campo es requerido', 'Este campo no puede contener menos de 3 letras']}
                    />
                    
                    <TextValidator
                        className={classes.form}
                        label="*Apellido"
                        onChange={handleName}
                        name="lastName"
                        value={name.lastName}
                        validators={['required', 'isName']}
                        errorMessages={['Este campo es requerido', 'Este campo no puede contener menos de 3 letras']}
                    />
                    
                   
                    <TextValidator
                        className={classes.form}
                        label="Dirección"
                        onChange={handleChange}
                        name="address"
                        value={name.address}
                    />
                     <TextValidator
                        className={classes.form}
                        label="*Teléfono de contacto"
                        onChange={handleChange}
                        onFocus={setCompleteName}
                        name="telephone"
                        type='number'
                        value={newPerson.telephone}
                        validators={['required', 'isName']}
                        errorMessages={['Este campo es requerido', 'Este campo no puede contener menos de 3 letras']}
                    />
                    
                    <TextValidator
                        value={newPerson.email.toLowerCase()}
                        className={classes.form}
                        label="*Email"
                        onChange={handleChange}
                        name="email"
                        validators={['required', 'isEmail','emailExist']}
                        errorMessages={['Este campo es requerido', 'Ingrese un email válido','Este email ya está registrado en TGD Soft,no puede estar duplicado.']}
                    />
                    
                    <TextValidator
                        value={newPerson.password}
                        className={classes.form}
                        label="*Password"
                        onChange={handleChange}
                        type='password'
                        name="password"
                        validators={['isPasswordLength', 'required']}
                        errorMessages={['La contraseña debe tener al menos 6 carácteres', 'Este campo no puede ir vacío']}
                    />

                                  
                    <Route path={`/${USER_ROUTES.home}/${useDimention()}/${USER_ROUTES.addUser}/${USER_ROUTES.addClient}`}>
                        {/* <InputLabel  id="demo-simple-select-helper-label">PERSONALIZADO</InputLabel> */}
                        <FormHelperText>Proporciona acceso global a una fábrica</FormHelperText>
                        <Select
                        className={classes.form}
                        name='tagF'
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"

                        value={newPerson.tagF}
                        onChange={handleChange}
                        >
                            <MenuItem value={'PERSONALIZADO'}><em>PERSONALIZADO</em></MenuItem>
                            {factoriesByUser &&
                            factoriesByUser.map((item,index) =>
                                <MenuItem value={item.tagF} key={index}>{item.tagF}</MenuItem>
                            )}
                        </Select>
                        
                    </Route>


                    <FormControl disabled >
                        <InputLabel htmlFor="component-error">Referente a cargo</InputLabel>
                        <Input
                            value={loggedInPerson ? loggedInPerson[0].id :0}
                            name='referrer'
                            type='number'
                            aria-describedby=""//component-error-text
                        />
                        <FormHelperText id="component-error-text"></FormHelperText>
                    </FormControl>

                    {/* <Button type='submit' className={classes.fabAdd}> */}
                        <Fab type='submit' color="primary" aria-label="add" >
                            {status === 'loading' ? <CircularProgress color='light'/> : <AddIcon />}
                        </Fab>
                    {/* </Button> */}
                    
                </ValidatorForm>

                
            </div>
        </Paper>
    );
}




export default AddPersonsForm;
