import React,{useState,useEffect } from 'react'
//Components
import './App.css'
import Loading from './components/presentational/Loading';
import LoginPage from './pages/pageLogin'
import EaseInOut from './components/presentational/EaseInOut'

//services
import { useValidateToken, useToken } from './services/session.service'
import {USER_ROUTES} from './services/tgd.config'

//REACT ROUTER
import {Route, Redirect, useLocation, Switch} from 'react-router-dom'
import LoginBackground from './components/presentational/LoginBackground'
import UserRole from './layouts/UserRole'
//RQ
import { ReactQueryDevtools } from 'react-query-devtools'





import LogOutPage from './pages/LogOutPage';

const LogValidation = ({path, children}) => {
    const [data,status] = useValidateToken()

    if(status === 'loading'){
         return (<> 
         <Redirect to={path}/> 
         <Loading /> </>)
        }

    if(data) return(<Route path={path} >{children} </Route>)

    if(!data){
        sessionStorage.clear()
        alert('su sesion ha expirado')
    }

    return <LoginPage></LoginPage> //modal para reloguearse
}

const useTag = ()=>{
    useEffect(()=> {
        console.log(`%c 
        ▒▒▄▀▀▀▀▀▄▒▒▒▒▒▄▄▄▄▄▄▄▒▒
        ▒▐░▄░░░▄░▌▒▒▄█▄DEV´S▄█▄▒
        ▒▐░▀▀░▀▀░▌▒▒▒▒░░░░░░░▒▒▒
        ▒▒▀▄░═░▄▀▒▒▒▒▒░░░░░░░▒▒▒
        ▒▒▐░▀▄▀░▌▒▒▒▒▒░░TGD░░▒▒▒     
        `, "font-family:monospace")
        
    },[])
}
const App = ()=>{

    const token = useToken()
    const {data:isValidated} = useValidateToken
    const [color, setColor] = useState('EFEBE9')
    const location = useLocation()
    useTag()

    return(
        <>
            <ReactQueryDevtools></ReactQueryDevtools>
            <LoginBackground bgColor={useToken() ? '002F6C' : 'f3f3f3'} />

            {!token && <EaseInOut children={ 
                <>
                    <LoginPage/> 
                </>
            } /> } 
            

            {token && <>
                <LogValidation path={`/${USER_ROUTES.home}`}>                         
                    <UserRole />
                </LogValidation>
                <Route path={`/${USER_ROUTES.login}`}>
                    <LogOutPage />
                </Route>
            </>}
        </>
   
    )

}


export default App