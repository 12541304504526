import React,{useContext} from 'react'
//components
import MenuPlantSelector from '../../components/MenuPlantSelector'
import NotificationsNavBar from '../../components/notifications/NotificationsNavBar';
//context
//MATERIAL
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavbarLogo from '../../assets/img/navBarLogo.png'
import {Chip, Hidden, Icon} from '@material-ui/core';
import BreadCrumbs from '../../components/presentational/BreadCrumbs';
import { useDimention, usePlants, useUser, useWorkspace  } from '../../services/person.service';
import { Link, NavLink } from 'react-router-dom';
import { USER_ROUTES } from '../../services/tgd.config';
import { SessionContext } from '../../store/sessionContext';




const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({


    hamButton:{
      [theme.breakpoints.up('sm')]:{
          display:'none',
      },
    },
    appBar:{
        [theme.breakpoints.up('md')]:{
            width:`calc(100% - ${drawerWidth}px)`,
            marginLeft:drawerWidth
        },
        zIndex:'',
    },
    title:{
        position:'relative',
      flexGrow: 1,
      margin: '0 8px'
  
    },
    spacing:{
      margin: '0 24px'
    },
    colorBlack:{
        color:'#333'
    },
    /* menu notification */
    toggleNotifications:{
        '&:hover':{
            backgroundColor:'#f6f6f6',
        }
    },
  }));

const NavBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const workspace = useWorkspace()
    const dimention = useDimention()
    const [auth] = useContext(SessionContext);
    //CONTEXT
    const {data:user ,error:userError ,success:userSuccess, isLoading:userLoading} = useUser()
    const {data:plants ,error:plantsError ,success:plantsSuccess, isLoading:plantsLoading} = usePlants()

    

    return (
        <div>
            <AppBar
                elevation={0}
                aria-label="menu"
                className={classes.appBar} 
                position="fixed"
                color='transparent'
                style={{ backgroundColor:'#fff'}}
            >
                <Toolbar /* className={classes.appBar} */>
                    {user && (user.email === 'd.lavallen@tgdsa.com') && 
                        <Chip 
                        component={NavLink} 
                        color={'primary'} 
                        label='Prospectos / Demo' 
                        clickable 
                        to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`} />
                    }
                    <Hidden mdUp>
                        <img src={NavbarLogo} style={{height:'40px',left:'24px'}} alt="TGD Soft"/>
                        {(user && (user.type === 'C') && (plants) && (plants.length > 1)) ? <MenuPlantSelector color="secondary" />:null}
                    </Hidden>
                    <Hidden smDown>
                        <BreadCrumbs />
                    </Hidden>
                    <Typography variant="subtitle1" noWrap className={classes.title }> </Typography>

                    <Hidden smDown>
                        <AccountCircle  />
                        {user && <Typography variant="button" noWrap className={classes.spacing} > {user.name} </Typography>}
                    </Hidden>
                    <Hidden smDown>
                        <NotificationsNavBar/>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton onClick={props.action}>
                            {theme.direction === 'rtl' ? <MenuIcon /> : <MenuIcon />}
                        </IconButton>
                    </Hidden>
                </Toolbar>
                    {/* <Hidden smUp>
                    <Toolbar>
                    {(user && (user.type === 'C') && (plants) && (plants.length > 1)) ? <MenuPlantSelector color="secondary" />:null}
                    </Toolbar>
                    </Hidden> */}
                <Divider/>
            </AppBar>
        </div>
    )
}

export default NavBar
