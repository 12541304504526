import React, {useState,useEffect} from 'react';
//routr
import {useHistory} from 'react-router-dom'






export const SessionContext = React.createContext()

//Making provider component to pass states 
export const SessionContextProvider = (props) => {

  //Context state for session auth
  const history = useHistory()

  const [auth,setAuth] = useState(()=>{
      const stortoredUser = sessionStorage.getItem('auth')
      return stortoredUser ? JSON.parse(stortoredUser) : {}
    }
  )

  useEffect(() => {
    sessionStorage.setItem('auth',JSON.stringify({
      workspace:null,
      /* token:sessionStorage.getItem('token'), */
    }))
  }, [auth.token]);

return(
    <SessionContext.Provider value={[auth,setAuth]} >
      {props.children}
    </SessionContext.Provider>
  )
}
