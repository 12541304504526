import React,{ useContext } from 'react';

//material
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { SessionContext } from '../../store/sessionContext';
import PageContainer from '../pageContainers/PageContainer';

const useStyles = makeStyles((theme) => ({

    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent:'center',
      margin: theme.spacing(3),
      width:'auto',
      '& > *': {
        margin: theme.spacing(3),
        flex:'1',
        minWidth: theme.spacing(30),
        height: theme.spacing(24),
        padding:theme.spacing(2),
        background: 'rgb(228,224,222)',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 100%, rgba(228,224,222,1) 0%)',         
        '&:hover':{
            boxShadow: '0px 0px 47px -16px rgba(0,0,0,0.75)',
            transition: 'all 300ms ease',
            cursor:'pointer',
            color:'#fff',
        }
        
        },
    },
    heroNavPlant:{
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(-10) ,
          },
          [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(-10) ,
            marginBottom: theme.spacing(0)
          },
          '& > *': {
            width:'calc(100% - 48px)',
            marginTop: theme.spacing(-10),
            marginLeft: theme.spacing(6),
            marginBottom: theme.spacing(4),
            color:'#f3f3f3',
            textTransform:'uppercase',
            fontWeight:'900',
            letterSpacing: '1px',
            },
    },
    heroNavTitles:{
        width:'160px',
        textTransform:'uppercase',
        fontWeight:'700',
        color:'#333',
    },
    hero:{
        height:'20vh',
        width:'100%',
        backgroundPosition:'center',
    },
  }));

const HomeHero = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const [auth] = useContext(SessionContext)
   
    const handleNavigation = (route) => {
        history.push(route)
    }

    return (
        <>
            <PageContainer>
                <Paper className={classes.hero} style={{backgroundColor:'#f3f3f3',backgroundImage:`url(${props.img})`,}} elevation={4}/>
                <div className={classes.heroNavPlant}>
                    <Typography variant="h5" component="h5">
                        {auth.workspace}
                    </Typography>
                </div>
            </PageContainer>
        </>
    );
}

export default HomeHero;
