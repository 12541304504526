import React,{useState} from 'react';
import { usePlants, useUser } from '../services/person.service';
import {useToken} from '../services/session.service'
import { useAssignCancelUserPlant, useUserActivatedPlants } from '../services/plant.service';
import {  CircularProgress,  useTheme } from '@material-ui/core';
import ResourceGrid from './grids/ResourceGrid';


const AssignCancelPlantsRefactor = ({action,title,userData,roleToEdit}) =>{

    const theme = useTheme()
    const userEmail = userData.email
    const {data:user} =useUser()
    const {data:plants,isLoading} = usePlants()//user plants (it has to be n administrator user type)
    
    const [userActivePlants,{isFetching}] = useUserActivatedPlants(userEmail)

    const [handleUserPlants] = useAssignCancelUserPlant(userEmail)
    const handleCLick = (item) => {
        let parameters ={
            email:userEmail,
            tagFP:item.tagFP,
            role:roleToEdit,
            state:action.charAt(0).toUpperCase(),
            actualUserEmail:user.email
        }

        handleUserPlants(action,parameters)
    }

    


    if(action === 'assign'){
        
        let filteredItems = () => { //filter array of items by items from an other array
            let tags = []
            let results = []
            if(userActivePlants){
                userActivePlants.forEach(element => {
                    tags.push(element.tagFP)
                });
                if(userActivePlants){
                    results = plants.filter(item => !tags.includes(item.tagFP))
                }
            }
            return results
        }

        let handleDisable = () => {
            if((roleToEdit ==='O')||(roleToEdit==='I') ){
                if(userActivePlants && (userActivePlants.length > 0)){
                   return true
                }
                return false 
            }
            return false
        }
        
        
        return(
            <>
                <ResourceGrid 
                    color={theme.palette.primary.main}
                    disabled={handleDisable()}
                    allResources={filteredItems()}
                    title={title}
                    action={handleCLick}
                    onLoad={isLoading ? <CircularProgress />:'Este usuario no tiene plantas para asignar'}
                    />
            </>
        )
    }

    if(action === 'cancel'){

        return(
            <>
                <ResourceGrid 
                    color={theme.palette.primary.main}
                    allResources={userActivePlants}
                    title={title}
                    disabled={false}
                    action={handleCLick}
                    onLoad={isFetching ? <CircularProgress />:'Este usuario no tiene plantas asignadas'}
                    />
            </>
        )
    }
}
export default AssignCancelPlantsRefactor