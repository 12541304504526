import axios from 'axios'
import {URL} from './tgd.config'


export const getAuth = (data) => {

    return axios.post(`${URL}/authenticate`,data,null)

}

export const getTokenVerification = (endpoint,token) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`
    }
    return axios(config)
}


//---------------------------------------------
//GET
//---------------------------------------------


//GET USER
export const getUser = (token) => {

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/User`
    }
    return axios(config)
};



//GET PERSON

//GET PLANTS
export const getPlants = (endpoint,token) => {


    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`
    }
    return axios(config)
};

//Hook get Entity
export const getAllPlants = (token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/AllPlants`,
    }
    return axios(config)
}

//GET ALL FACTORIES
export const getAllFactories = (token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/AllFactories`,
    }
    return axios(config)
}


//GEt RESOURCES
export const getAllResources = (endpoint,token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/${endpoint}`,
    }
    return axios(config)
}

//GEt Account manager factories
export const getAccountManagerFactories = (email,token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/AccountManagerFactories/${email}`,
    }
    return axios(config)
}

//GET ALL ITEMS WITH EMAIL
export const getAllItemsByEmail = (endpoint, email, token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/${endpoint}/${email}`,
    }
    return axios(config)
}


// GET Notifications
export const getNotifications = (endpoint,token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;

    let config={
        method:'get',
        url:`${URL}/${endpoint}`
    }
    return axios(config)
}

//GET PERSONS
export const getAllPersons = (token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/AllPerson`,
    }
    return axios(config)
}


//get All CLients
export const getAllClients = (token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/AllClients`,
    }
    return axios(config)
}

//GET Clients by factory
export const getClientsByFactory = (token,tagF) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/FactoryClients/${tagF}`,
    }
    return axios(config)
}

//get plants of an Engineer

export const userPlants = (token,email) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/UserPlantsState/${email}`,
    }
    return axios(config)
}

//GET ALL PLANT OPERATORS
export const getOperatorsByPlant = (tagFP,token) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = `${tagFP}`;
    
    let config = {
        method: "get",
        url:`${URL}/PlantOperators`,
    }

    return axios(config)

}
export const getOperatorsByFactory = (tagF,token) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "get",
        url:`${URL}/FactoryOperators/${tagF}`,
    }
    
    return axios(config)

}

//---------------------------------------------
//CREATE
//---------------------------------------------

//CREATE PERSON
export const createPerson = (token, parameters, endpoint) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/${endpoint}`,
        data:parameters
    }
    return axios(config)
}

//Create Client 
export const createClient = (token, parameters) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/CreateClient`,
        data:parameters
    }
    return axios(config)
}

//CREATE FACTORY
export const createFactory = (token, parameters) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/CreateFactory`,
        data:parameters
    }
    return axios(config)
}

//CREATE PLANT
export const createPlant = (token, parameters) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/CreatePlant`,
        data:parameters
    }
    return axios(config)
}



//---------------------------------------------
//ASIGN
//---------------------------------------------

//hook AsignClient
export const assignCancelClientToManager = (parameters,token) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/AssignCancelClientAccount`,
        data: parameters
    }
    return axios(config)
}

export const assignPlant = (parameters,token) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`${URL}/AssignPlant`,
        data: parameters
    }
    return axios(config)
}


//---------------------------------------------
//CANCEL
//---------------------------------------------



//---------------------------------------------
//UPDATE
//---------------------------------------------

export const updatePlant = (parameters,token) =>{
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`${URL}/UpdatePlant`,
        data:parameters
    }
    return axios(config)
}

