import React, {useState,useEffect} from 'react'
import axios from 'axios' 
import { useQuery, queryCache, useMutation } from 'react-query'
import { PBI_URL, URL } from './tgd.config'
import { useUser, useWorkspace } from './person.service'
import { useToken } from './session.service'




//--------------------------------------
//Axios PBI querys
//--------------------------------------


export const getPbiAccessToken = (_,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;

    let config={
        method:'get',
        url:`${URL}/pbi`,
        body:{}
    }
    
    let res = axios(config).then(res => {
        sessionStorage.setItem('aadToken',res.data)
        return res.data
    })
    .catch (res => {return res.data})
    return res
}

export const getPbiInfo = (endpoint, token) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url: `${PBI_URL}/${endpoint}`,
    }
    return axios(config).then(res=>{return res}).catch(res=>{return res})
}

export const getPbiResourceByGroupID = (endpoint,groupId,token) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config={
        method:'get',
        url:`${PBI_URL}/groups/${groupId}/${endpoint}`,
    }
    let res = axios(config)
    .then(res=>{
        let response = res.data.value
         return response
        }).catch(res=>{return res})
    return res
}



export const getPbiResourceIngDashboard = (endpoint,groupId,dashboardId,token) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config={
        method:'get',
        url:`${PBI_URL}/groups/${groupId}/dashboards/${dashboardId}/${endpoint}`,
    }
    let res = axios(config)
    .then(res=>{
        let response = res.data.value
         return response
        }).catch(res=>{return res})
    return res

}

export const addUserInPbiGroup = ({groupId,parameters,token}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users`,
        headers: {}, 
        data:parameters
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

//delete PBI GROIUP USER
export const deleteUserInPbiGroup = ({groupId,user,token}) => {
    axios.defaults.headers.delete['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "delete",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users/${user}`,
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}





//--------------------------------------
//react-Query HOOKS
//--------------------------------------

export const usePbiAccessToken = () => {
    const token = useToken()
    const query = useQuery(['aadToken',token],getPbiAccessToken,{
        retry:0,
        refetchOnWindowFocus:false,
        enabled:token
    })
    if (query.data && query.data.includes('error')) return {data:null,refetch:query.refetch}
    else return query
}

export const useAadToken = () => {
    const data = sessionStorage.getItem('aadToken')
    if (data) return data
    else return null
}

export const useReports = () => {
    const data = queryCache.getQueryData('reports')
    return data
}

export const usePbiGroups = () => {
    const token = useAadToken()
    return useQuery(['groups', token], getPbiInfo,{
        retry:0,
        enabled:!!token
    })
}

/* export const usePbiGroupsToAssignWorkspace = () => {
    const token = useAadToken()
    return useQuery(['groupsToAssignWorkspace', token], getPbiInfo,{
        enabled:token != null
    })
} */

export const usePbiReportsInGroup = () => {
    const groupId = usePbiIdByName()
    const token = useAadToken()
    const {data:reports} =useQuery(['reports', groupId, token], getPbiResourceByGroupID,{
        retry:0,
        enabled:!!groupId
    })
    if(reports) return reports
}

export const usePbiDashboardsInGroup = () => {
    const groupId = usePbiIdByName()
    const token = useAadToken()
    const {data:dashboards} = useQuery(['dashboards', groupId, token], getPbiResourceByGroupID,{
        retry:0,
        enabled:!!groupId
    })
    if(dashboards) return dashboards
}

export const usePbiHomeDashboard = () => {
    const dashboards = usePbiDashboardsInGroup()
    let homeDashboard = {}
    if(dashboards){
        homeDashboard = dashboards.filter(item => item.displayName.toLowerCase() === 'home')
        if (homeDashboard.length > 0){
            return homeDashboard[0].id
        }
    }
}


export const usePbiTilesInDashboard = () => {
    const groupId = usePbiIdByName()
    const dashboardId = usePbiHomeDashboard()
    const token = useAadToken()
    return useQuery(['tiles',groupId,dashboardId,token],getPbiResourceIngDashboard,{
        retry:0,
        enabled:!!dashboardId
    })
}

//--------------------------------------
//mutation
//--------------------------------------

export const useAddUserInPbiGroup = () => {
    return useMutation(addUserInPbiGroup)
}
export const useDeleteUserInPbiGroup = () => {
    return useMutation(deleteUserInPbiGroup)
}


//--------------------------------------
//CUSTOM HOOKS
//--------------------------------------


export const usePbiIdByName = () => {
    const workspace = useWorkspace()
    const{data:user} = useUser()
    const token = useAadToken()
    const {data:groups} = usePbiGroups()
    let groupId = null
    if(user && groups && workspace ){
        switch (user.type) {
            case 'C':
                groupId = groups.data.value.filter(item => item.name === workspace)[0].id
                return groupId
                break;
            
            default :
                return null
                break
          
        }   
    }
}




