import React, { useEffect, useState } from 'react';
import { Button, Icon,makeStyles,IconButton, useTheme} from '@material-ui/core'
import { usePlants, useUser } from '../../services/person.service';
import TgdModal from '../TgdModal';
import TgdList from './TgdList';
import TgdListToolbar from './TgdListToolbar';
import ListContainer from '../pageContainers/ListContainer';
import AddPlantFormRefactor from '../forms/AddPlantFormRefactor';
import DuoContainer from '../pageContainers/DuoContainer';
import PlantsGrid from '../Admin/AddClient/Grids/PlantsGrid'

import FactorySelectorForm from '../forms/FactorySelectorForm';
 
const useStyles = makeStyles((theme) => ({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    },
    relative:{
        position:'relative'
    }
  }));

      

const PlantList = () => {
    const classes = useStyles()
    const theme = useTheme()
    const [open,setOpen] = useState(false)
    const {data:user} = useUser()
    const [filteredResources, setFilteredResources] = useState();
    const {data:plants,isFetching} = usePlants()
    const [search,setSearch] = useState('')
    const [factorySelected,setFactorySelected] = useState({
        name:'',
        factoryId:''
    })
    const [newPlant,setNewPlant] = useState(false)

    useEffect(() => {//Search logic
        if(plants){
            let searched = plants.filter(item => item.name.toLowerCase().includes(search.trim()))
            setFilteredResources(searched)
        }
    }, [search]);

    useEffect(()=> {
        if(plants){
            setFilteredResources(plants)
        }
    },[plants])

    
    const handleSearch = (e) =>{
        setSearch(e.target.value.toLowerCase())
    }

    const handleNewPlant = () =>{
        setNewPlant(true)
    }

    const handleModal = () => {
        setOpen(!open)
    }

    const customAction = () => {
        handleModal()
    }
    const customButtons = (item) => {
        if(user && (user.type !== 'I' ) && (user.type !== 'O')){
            return(
                <IconButton style={{margin:'0 0'}} variant='outlined' edge="end" aria-label="Asignar Plantas" onClick={()=>customAction(item)} color={'primary'}>
                    <Icon>business</Icon>
                </IconButton > 
            )
        }
    }


    return (
        <ListContainer>
            <TgdListToolbar 
                searchBar={true}
                title={'Plantas'}
                searchValue={search}
                handleSearch={handleSearch}
                actions={(user.type !== 'AC') && <Button color={'secondary'} onClick={handleModal} variant='contained'><Icon>add</Icon>Planta</Button>}
            />
            <TgdList
                title={'Plantas'}
                icon={'business'}
                status={isFetching}
                items={filteredResources}
                toggleDisabled={true}
            />
            <TgdModal fullWidth={true} title={'Crea una Planta'} open={open} handleClose={handleModal}>
                <FactorySelectorForm handleFactory={setFactorySelected}/>
                {!!factorySelected.name &&
                <DuoContainer>
                    <PlantsGrid 
                    color={theme.palette.primary.main}
                    selectByFactory={factorySelected}
                    newPlant={newPlant}
                    />
                    <AddPlantFormRefactor
                    color={theme.palette.primary.main}
                    handlePlant={handleNewPlant}
                    selectByFactory={factorySelected}/>
                </DuoContainer>
                }
            </TgdModal>
        </ListContainer>
    );
}

export default PlantList;
;
