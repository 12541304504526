import axios from 'axios'

const URL = 'https://api.powerbi.com/v1.0/myorg'


export const getPbiGroups = (token) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "get",
        url: `${URL}/groups`,
    }
    return axios(config)
}

export const getPbiAccesToken = (URL) => {
    let config={
        method:'get',
        url:URL,
        body:{}
    }
    return axios(config)
}

export const getPbiReportsInGroup = (URL,token) =>{
    let config={
        method:'get',
        url:URL,
        Headers:{
            authorization:'Bearer '+token
        }
    }
    return axios(config)
}

//Asign workspace to pbi user
export const asignWorkSpaceToPbiUser = (token, groupId, email) =>{
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users`,
        body:{
            emailAddress: email,
            groupUserAccessRight: 'Contributor'
          }
    }
    return axios(config)
}

//hook create new worspace on power bi group
export const createPbiGroup = (token, parameters) => {

    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config = {
        method: "post",
        url:'https://api.powerbi.com/v1.0/myorg/groups',
        headers: {}, 
        data:{name: parameters}
    }
    return axios(config)

}


//refresh PBI GROUPS
export const refreshPbiUserPermissions = (token) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`https://api.powerbi.com/v1.0/myorg/RefreshUserPermissions`,
        headers:{
            contentType: 'application/json'
        }
    }
    return axios(config)
}


//Hook PowerBi
export const addUserInPbiGroup = (groupId,parameters,token) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "post",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users`,
        headers: {}, 
        data:parameters
    }
    return axios(config)
}


//delete PBI GROIUP USER
export const deleteUserInPbiGroup = (groupId,user,token) => {
    axios.defaults.headers.delete['Authorization'] = 'Bearer ' + token;
    let config = {
        method: "delete",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users/${user}`,
    }
    return axios(config)
}






