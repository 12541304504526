import React from 'react';
import '../../assets/css/EaseInOut.css'


const EaseInOut = (props) => {
    return (
        <div className='animation'>
            {props.children}
        </div>
    );
}

export default EaseInOut;
