import React,{useState,useContext, useEffect} from 'react';
//Serices
import {getAllFactories} from '../../services/axiosTgd'
//material
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { CircularProgress, Paper } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { useToken } from '../../services/session.service';
import { useAllFactories, useAssignCancelFactory, useCreateFactory } from '../../services/factory.service';
import { Alert } from '@material-ui/lab';
import { useUser } from '../../services/person.service';


const useStyles = makeStyles((theme) => ({
    componentContainer:{
        position:"relative",
        padding:'24px',
        margin:'24px 0 0 0',
        height:'min-content'
    },
    formContainer:{
        display:'grid',
        justifyContent:'start', 
        gap:'16px',
        gridTemplateColumns:'100%',
        alignItems:'starts',
        margin:'24px 0 0 0',
    },
    fabAdd: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));


const AddFactoryFormRefactor = ({color,handleFactory}) => {
    const classes = useStyles()
    const token = useToken()
    const {data:user} = useUser
    const [load,setLoad] = useState({
        loading:null,
        success:false,
        error:'',
    })
    const [newFactory,setNewFactory] = useState({
        location:'',
        name:'',
        businessName:'',
        referrer:''
    })   
    const [createFactory,{status}]=useCreateFactory()
    const {data:factories,refetch} = useAllFactories()


    //Catch form values
    const handleChange = (e) => {
        setNewFactory({...newFactory,[e.target.name]:e.target.value})
    };
    const handleReferrer = () => {
        setNewFactory({...newFactory,referrer:'desarrollo@tgdsa.com'})
        
    }

    //varify forms and create Factory
    const sendFactory = () => {
        if(factories){
            setLoad({...load,loading:true})
            let factoryName = newFactory.name.toUpperCase()
            let count = factories.filter(f => f.tagF === factoryName).length
            if(count > 0){
                setLoad({...load,error:'Este nombre ya existe'})
            }else{    
                if((newFactory.name != '') && (newFactory.name != ' ') && (newFactory.name!= '  ') && (newFactory.name != '   ') && (newFactory.name != null)){
                    createFactory({newFactory:newFactory,token:token})
                    .then((res)=>{
                        refetch()
                        handleFactory(res.data)
                        setLoad({...load,success:true,error:''})
                    })
                    .catch(err => {
                        console.log(err)
                        setLoad({...load,success:false,error:err})
                    })
                }else{
                    setLoad({...load,error:'El nombre de la fábrica no puede ir vacío'})
                }
            }

        }
    }


    if(status === 'error') return <Alert severity={status}>Ha ocurrido un error, Vuelva a intentarlo</Alert>
    if(status === 'success')return <Alert severity={status}>La Fábrica se ha creado exitosamente</Alert>

        return (
                <Paper className={classes.componentContainer} style={{borderTop:`4px solid ${color}`}}>
            {/*   <Toolbar style={{justifyContent:'space-between',padding:'8px'}}>
                    <Typography variant='button' component={'span'}>Agrega una nueva Fabrica</Typography>
                </Toolbar> */}
                <div className={classes.formContainer}>
                    {(load.error)
                    ?   <FormControl error >
                            <InputLabel htmlFor="component-error">Nombre fantasía o marca de la fabrica</InputLabel>
                            <Input
                            id="component-error"
                            value={newFactory.name}
                            name='name'
                            onChange={handleChange}
                            onFocus={handleReferrer}
                            aria-describedby="component-error-text"
                            />
                            <FormHelperText id="component-error-text">{load.error}</FormHelperText>
                        </FormControl> 
                    :   <FormControl >
                            <InputLabel htmlFor="component-error">Nombre fantasía o marca de la fabrica</InputLabel>
                            <Input
                            id="component-error"
                            value={newFactory.name}
                            name='name'
                            onChange={handleChange}
                            onFocus={handleReferrer}
                            aria-describedby=""//component-error-text
                            />
                            <FormHelperText id="component-error-text"></FormHelperText>
                        </FormControl> }
                    
                    <FormControl  >
                        <InputLabel htmlFor="component-error">Razón Social</InputLabel>
                        <Input
                        id="component-error"
                        value={newFactory.businessName}
                        name='businessName'
                        onChange={handleChange}
                        aria-describedby=""//component-error-text
                        />
                        <FormHelperText id="component-error-text"></FormHelperText>
                    </FormControl>
                    
                    <FormControl  >
                        <InputLabel htmlFor="component-error">Región Comercial(país)</InputLabel>
                        <Input
                        id="component-error"
                        value={newFactory.location}
                        name='location'
                        onChange={handleChange}
                        aria-describedby=""//component-error-text
                        />
                        <FormHelperText id="component-error-text"></FormHelperText>
                    </FormControl>

                    <FormControl disabled>
                        <InputLabel htmlFor="component-error">Encargado de fábrica</InputLabel>
                        <Input
                            id="component-error"
                            value={'desarrollo@tgdsa.com'}
                            name='referrer'
                            aria-describedby=""//component-error-text
                            />
                        <FormHelperText id="component-error-text"></FormHelperText>
                    </FormControl>
                </div>
                <Fab onClick={sendFactory} color="primary" className={classes.fabAdd} aria-label="add">
                    {status==='loading' ? <CircularProgress color='light'/> :<AddIcon />}
                </Fab>
            </Paper>
        );
    
}

export default AddFactoryFormRefactor;
