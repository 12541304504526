import { makeStyles, TextField, Toolbar, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    }
  }));

const TgdListToolbar = ({title,searchValue,handleSearch,actions,searchBar}) => {
    const classes = useStyles()
    return (
            <Toolbar className={classes.FactoryListTolbar}>
                <Typography variant='h6'>{title}</Typography>
                {searchBar && 
                    <TextField 
                    style={{width:'300px',margin:'0 16px'}}
                    value={searchValue}
                    type='search' 
                    onChange={handleSearch} 
                    placeholder='buscar...' 
                    />
                }
                {actions}
            </Toolbar> 
    );
}

export default TgdListToolbar;
