//data
import clientData from '../data/CLientData.json'
import adminData from '../data/AdminData.json'
import accountManagerData from '../data/AccountManager.json'
import operatorData from '../data/OperatorData.json'
import engineerData from '../data/EngineerData.json'
import superAdminData from '../data/SuperAdminData.json'
import { useUser } from './person.service';



//--------------------------------------
//TGD CONFIG
//--------------------------------------



export const PBI_URL = `https://api.powerbi.com/v1.0/myorg` //powerBi Api endpoints

//Development
//--------------------------------------

//export const URL = 'http://192.168.1.7:8080'//Dev backend

//Testing
//--------------------------------------

export const URL = 'https://backtesting.tgdcompany.com'// Back TESTING 

//Production
//--------------------------------------

//export const URL = 'https://java.tgdcompany.com'//Production Back  


export const USER_ROUTES = {
    login:'login',
    home:'admin',
    notifications:'notificaciones',
    verification:'verification',
    superAdmin:'Super-Admin',
    administrator:'Administrador',
    accountManager:'Administrador-de-Cuentas',
    client:'Cliente',
    engineer:'Supervisor',
    operator:'Operario',
    addUser:'nuevo-usuario',
    plantSelector:'plant-selector',
    factories:'fabricas',
    plants:'plantas',
    addClient:'agregar-cliente',
    config:'configuracion',
    demoUsers:'nuevo-usuario-demo',
    RejectedDemoUsers:'archivados',
    demoRequest:'demo'
}


//--------------------------------------
//CUSTOM HOOKS
//--------------------------------------


//Menus
export const useMenu = () => {
  
    const {data:user} = useUser()
    let dimentionMenu = {}
    const userMenu = {
        superAdminData:superAdminData.menu,
        accountManagerData:accountManagerData.menu,
        adminData:adminData.menu,
        engineerData:engineerData.menu,
        clientData:clientData.menu,
        operatorData:operatorData.menu  
    }

    
    if ((user) && (userMenu != null)){
        switch (user.type) {
  
            case 'SA':
                dimentionMenu = userMenu.superAdminData
                return dimentionMenu
                break
  
            case 'AC':
                dimentionMenu = userMenu.accountManagerData
                return dimentionMenu
                break
  
            case 'A':
                dimentionMenu = userMenu.adminData
                return dimentionMenu
                break
  
            case 'I':
                dimentionMenu = userMenu.engineerData
                return dimentionMenu
                break
  
            case 'C':
                dimentionMenu = userMenu.clientData
                return dimentionMenu
                break
  
            case 'O':
                dimentionMenu = userMenu.operatorData
                return dimentionMenu
                break

            default: 
                return null
                break
        }
    }
}

//--------------------------------------
//SERVICE
//--------------------------------------


export const setNewMenu = (NewMenuItems,defaultMenuItems) => {
    let newMenu =[]
    const {data,names} =getIndicators(NewMenuItems)

    defaultMenuItems.forEach(item => newMenu.push(item))
    data.forEach((fMitem,i) =>{
        let subItems = NewMenuItems.filter(item=> item.name.toLowerCase().includes(fMitem.toLowerCase()))
        newMenu.push(newMenuItemWithSubMenu(i,subItems,fMitem,'bubble_chart'))
    })

    let filters = ['control','privado','usage']
    let filteredNewMenu = newMenu.filter(item => {
            if(item.name.toLowerCase().includes(filters[0]))return false
            if(item.name.toLowerCase().includes(filters[1]))return false
            if(item.name.toLowerCase().includes(filters[2]))return false
            else return true
    })

    /* console.log(filteredNewMenu) */
    return filteredNewMenu
}



//new menu item with sub items.
export const newMenuItemWithSubMenu = (itemId,subItems,fMitem,itemIcon) =>{
    let AM = [] //new ItemArray to Create sub Items  
    const splitNameOn = (string,caracter) => {
        if(string.includes(caracter)){
            return string.split('.')
        }
        return string
    }

    subItems.forEach(item => { //create sub Items
        AM.push({
            name:splitNameOn(item.name,'.').slice(1),
            route:item.name,
            icon:"donut_large"
        })
        return AM
    })

    const isMultyMenu = () => {
        /* console.log(subItems) */
        const res = subItems.filter(item => item.name.includes('.'))
        /* console.log(res) */
        
        if(res.length > 0) return true
        if(res.length === 0) return false
    }

    
    
    
    const newMenuItemFiltered = () => {
        let newMenuItem = null

        if(isMultyMenu()){
            return newMenuItem = {//item to add
                id:itemId,
                name:splitNameOn(fMitem,'.'),
                route:fMitem.toLowerCase(),
                icon:itemIcon,
                actionMenu:AM,
            }
        }else{
            return newMenuItem = {//item to add
                name:fMitem,
                route:fMitem.toLowerCase(),
                icon:itemIcon
            }
        }
        
    }
    
    

    return newMenuItemFiltered()
}

export const stringProcesor = (stringArray) => {
    let keys =[]
    
    stringArray.forEach(string=> {
            keys.push(string.split('.'))
    })
    //console.log(keys)
    return keys
}

//
export const getIndicators = (reportsArray) => {
    let data = []
    let names = []

    
    reportsArray.forEach(report =>{
        names.push(report.name)
    })
    
    reportsArray.forEach(report =>{
        stringProcesor(names).forEach(indicatorsArray =>{
            if(data.filter(dataItem => dataItem === indicatorsArray[0]).length == 0){
                data.push(indicatorsArray[0])
            }
        })
    })
    
    let respuesta ={
        data,
        names
    }

    
    return respuesta
}


//return objects that user have access filtering an array of objects
export const filterAccessTo = (role,arrayObject) => {

       
        let range=[]
        let accessTo=[]

          switch(role){ //specific filter
            case'SA':
                range = roleAccesCombinations(100)
                break
            case'A':
                range = roleAccesCombinations(10)
                break
            case'AC':
                range = roleAccesCombinations(50)
                break
            case'I':
                range = roleAccesCombinations(4)
                break
            case'C':
                range = roleAccesCombinations(3)
                break
            case'O':
                range = roleAccesCombinations(5)
                break
        } 
        
        arrayObject.forEach(object=>{
            if(range.filter(i => i === object.access).length > 0){
                accessTo.push(object)
            }
        })
    
        return accessTo
    
}

//Calculate all numbers posible by role (return an array of numbers)
export const roleAccesCombinations = (role) => {

    let roleValues = [100,10,50,3,4,5]
    let n = 0
    let allPosibleAccess = []
    let roleResult = [] 

    //all simple level variants
    for(n;n<6;n+1){
        if(role != roleValues[n] ){
            roleResult.push(role + roleValues[n])
        }
        n = n+1
    }

    //all Complex level variants
    mixedResoults(role).forEach(i=>{
        roleResult.push(i)
    })

    //set Acces by role in an Array
    allPosibleAccess = roleResult
    
    return allPosibleAccess
}

//Part of roleAccesCombinations make the  bidimentional array calculations
export const mixedResoults = (position) => {

    let roleValues = [100,10,50,3,4,5]
    let roleAccess = []

    let mixedRoleValues = position
    
   
    let counter= 0
    roleValues.forEach(role => {

        if(position != role){
            
            mixedRoleValues = mixedRoleValues + role
            roleAccess.push(mixedRoleValues)
            let n = counter

            for(n=counter;n<6;n++){
                if((position != roleValues[n])){
                    if(role != roleValues[n]){
                        let result = mixedRoleValues + roleValues[n]
                        roleAccess.push(result)
                    }
                }
            }

        }

        counter++
    })
    return(roleAccess)
}

export const calcDate = (date) => {
    let date2 = new Date(date);
    let date1 = new Date()
    let substract = date2.getTime() - date1.getTime()
    if((Math.round(substract/ (1000*60*60*24))) === -1) return 0
    return(Math.round(substract/ (1000*60*60*24)))
}